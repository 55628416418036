import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import withRoleChecking from 'hoc/withRoleChecking'
import { useCustomersList } from '../data/CustomerListData'
import CustomerPageTable from '../components/CustomerPageTable'
import { Customer } from '../data/iCustomerList'

function CustomerListPage() {
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`
  const [showFilterForm, setShowFilterForm] = useState(false)
  const [filteredCustomer, setFilteredCustomer] = useState<Customer[]>()
  const { genericEventHandler } = useGenericEventHandler()
  useEffect(() => {
    document.title = 'Profile - Customers'
  }, [])

  useEffect(() => {
    function hideFilterFormHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && showFilterForm) {
        setShowFilterForm(false)
      }
    }
    window.addEventListener('keyup', hideFilterFormHandler)

    return () => {
      window.removeEventListener('keyup', hideFilterFormHandler)
    }
  }, [showFilterForm])

  const { data, isLoading, isError, error } = useCustomersList(redirectPageURL, true)

  useEffect(() => {
    if (isError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error,
        message: error.message || 'Error in Customer List Data',
        extraData: {
          component: 'CustomerListPage',
          action: 'useCustomersList',
        },
      })
    }
  }, [error, isError])

  return (
    <div
      id='customers-main'
      className='flex flex-col h-full w-full pt-3'
      aria-hidden='true'
      onClick={() => showFilterForm && setShowFilterForm(false)}
    >
      <CustomerPageTable
        tableData={
          data
            ?.filter((customer) => customer.id !== 'all')
            .map((customer) => ({
              ...customer,
              billingPlanName: customer.billingPlans?.name,
              billingPlanType: customer.billingPlans?.billingPlanType,
            })) || []
        }
        isLoading={isLoading}
        filterValues={[]}
        updateFilteredData={setFilteredCustomer}
        onShowFilter={() => {}}
        onFilterFormSubmit={() => {}}
        onFilterFormReset={() => {}}
      />
    </div>
  )
}

export default withRoleChecking(CustomerListPage, [], true)

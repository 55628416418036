import { ArrowLeft } from 'assets/icons'
import { DateRange } from 'forms/FormModelInterface'
import { calDueDateRangeOptions } from 'forms/FormUtils'
import ErrorPage from 'pages/common/ErrorPage'
import React from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { Link } from 'react-router-dom'
import { DeviceCalDueTypes } from 'utils/CommonEnums'

interface iGenericWidgetProps {
  value: number
  totalValue: number
  customerId?: string
  businessUnitId?: string
}

function DashboardDueIn90DaysWidget(props: iGenericWidgetProps) {
  const { value, totalValue, customerId, businessUnitId } = props

  const actualValue = totalValue !== 0 ? (value / totalValue) * 100 : 0
  const valuePercentage = actualValue.toFixed()

  const calDueStatus = calDueDateRangeOptions.find(
    (option) => option.label === DeviceCalDueTypes.DueIn90Days,
  )?.label
  const calDueDateRange = calDueDateRangeOptions.find(
    (option) => option.label === DeviceCalDueTypes.DueIn90Days,
  )?.value as DateRange
  const calDueStartDate = new Date(calDueDateRange?.startDate).toISOString().split('T')[0]
  const calDueEndDate = new Date(calDueDateRange?.endDate).toISOString().split('T')[0]

  const urlsearchParams = new URLSearchParams()
  if (customerId) {
    urlsearchParams.set('c', customerId)
  }
  if (businessUnitId) {
    urlsearchParams.set('mainbu', businessUnitId)
  }
  urlsearchParams.set('calduesel', calDueStatus as string)
  urlsearchParams.set('calduefrom', calDueStartDate as string)
  urlsearchParams.set('caldueto', calDueEndDate)

  const reportLink = `/web/calibration?${urlsearchParams.toString()}`
  // const reportLink = `/web/calibration?calduesel=${calDueStatus}&calduefrom=${calDueStartDate}&caldueto=${calDueEndDate}`

  return (
    <div>
      {/* <h3 className='text-base font-semibold leading-6 text-gray-900'>Last 30 days</h3> */}
      {/* <dl className='mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0'> */}

      <div className='px-2 py-1 sm:p-1'>
        <dl>
          <dt>
            <div className='max-w-max bg-c-green-2 text-c-dark-blue-1 rounded-full px-3 py-1 font-semibold text-xs leading-4'>
              Due in 90 days
            </div>
          </dt>
          <dd className='mt-10 flex items-baseline justify-between md:block lg:flex'>
            <div className='flex items-baseline text-2xl font-semibold text-c-dark-blue-4'>
              {value}
              <span className='ml-2 text-sm font-medium text-c-light-gray-1'>of {totalValue}</span>
            </div>

            {/* <div
              className={classNames(
                // 'bg-green-100 text-green-800'
                'bg-red-100 text-red-800',
                'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
              )}
            >
              View
            </div> */}
          </dd>
          <dd className='flex items-baseline justify-between md:block lg:flex'>
            <div className='text-sm font-medium text-c-light-gray-1'>
              {actualValue >= 1 ? (
                valuePercentage
              ) : (
                <>
                  <ArrowLeft className='inline-block w-4 h-4 fill-c-light-gray-1' />1
                </>
              )}
              {' %'}
            </div>
            <Link
              to={reportLink}
              // target='_blank'
              // rel='noreferrer noopener'
              className='rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-c-dark-blue-1 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            >
              View
            </Link>
          </dd>
        </dl>
      </div>

      {/* </dl> */}
    </div>
  )
}

DashboardDueIn90DaysWidget.defaultProps = {
  customerId: undefined,
  businessUnitId: undefined,
}
export default withErrorBoundary(DashboardDueIn90DaysWidget, {
  FallbackComponent: ErrorPage,
})

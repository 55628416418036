import { DateRange, IFilterQueryParamValues, IselectOptionProps } from 'forms/FormModelInterface'
import { DeviceCalDueTypes } from 'utils/CommonEnums'

export const CalibrationPageColumnIds = {
  serialNumber: 'serialNumber',
  deviceType: 'deviceType',
  businessUnit: 'businessUnit',
  calibrationStatus: 'calDueStatus',

  lastCalSelector: 'lastCalSelector',
  calDueSelector: 'calDueSelector',
  lastCal: 'lastCalibratedDate',
  calDue: 'calibrationDueDate',

  deviceUser: 'deviceUser',
}

export const CalibrationPageParamLabels = {
  serialNumber: 'ser',
  calStatus: 'status',

  lastCalSelector: 'lcalsel',
  lastCalFrom: 'lcalfrom',
  lastCalTo: 'lcalto',

  calDueSelector: 'calduesel',
  calDueFrom: 'calduefrom',
  calDueTo: 'caldueto',

  businessUnit: 'cbu',
  deviceType: 'dtype',

  deviceUser: 'user',
}

export const CalibrationPageFilterParamValues: IFilterQueryParamValues[] = [
  { paramName: CalibrationPageParamLabels.serialNumber, paramValue: null },
  { paramName: CalibrationPageParamLabels.businessUnit, paramValue: null },
  { paramName: CalibrationPageParamLabels.deviceType, paramValue: null },
  { paramName: CalibrationPageParamLabels.calStatus, paramValue: null },
  { paramName: CalibrationPageParamLabels.lastCalSelector, paramValue: null },
  { paramName: CalibrationPageParamLabels.lastCalFrom, paramValue: null },
  { paramName: CalibrationPageParamLabels.lastCalTo, paramValue: null },
  { paramName: CalibrationPageParamLabels.calDueSelector, paramValue: null },
  { paramName: CalibrationPageParamLabels.calDueFrom, paramValue: null },
  { paramName: CalibrationPageParamLabels.calDueTo, paramValue: null },
  { paramName: CalibrationPageParamLabels.deviceUser, paramValue: null },
]

export type CalibrationPageFilterFormType = {
  serialNumber: IselectOptionProps

  deviceType: IselectOptionProps
  businessUnit: IselectOptionProps
  calibrationStatus: IselectOptionProps

  lastCalSelector: IselectOptionProps
  lastCal: DateRange

  calDueSelector: IselectOptionProps
  calDue: DateRange

  deviceUser: IselectOptionProps
}

export const CalenderOverDueStatus = {
  [DeviceCalDueTypes.Overdue]: 'bg-c-red-1 text-c-white',
  [DeviceCalDueTypes.DueIn7Days]: 'bg-c-red-light text-c-dark-blue-1',
  [DeviceCalDueTypes.DueIn30Days]: 'bg-c-blue-1 text-c-dark-blue-1',
  [DeviceCalDueTypes.DueIn60Days]: 'bg-c-green-3 text-c-dark-blue-1',
  [DeviceCalDueTypes.DueIn90Days]: 'bg-c-green-2 text-c-dark-blue-1',
  [DeviceCalDueTypes.DueOver90Days]: 'bg-c-green-1 text-c-dark-blue-1',
}

export const CalenderOverDueStatusText = {
  [DeviceCalDueTypes.Overdue]: 'text-c-red-1',
  [DeviceCalDueTypes.DueIn7Days]: 'text-c-red-light',
  [DeviceCalDueTypes.DueIn30Days]: 'text-c-blue-1',
  [DeviceCalDueTypes.DueIn60Days]: 'text-c-green-3',
  [DeviceCalDueTypes.DueIn90Days]: 'text-c-green-2',
  [DeviceCalDueTypes.DueOver90Days]: 'text-c-green-1',
}

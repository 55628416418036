import { CellContext, ColumnDef, Table } from '@tanstack/react-table'
import { EditIcon } from 'assets/icons'
import clsx from 'clsx'
import Show from 'components/atom/Show'
import TsTable from 'components/table/TsTable'
import { IFilterValues } from 'forms/FormModelInterface'
import React, { useMemo, useState } from 'react'
import { iBillingPlan } from 'data/CustomerListHook'
import { BillingPlanEnums } from 'utils/CommonEnums'
import Tooltip from 'components/atom/Tooltip'
import { AddEntryInTableButton } from 'components/table/tsTableComponents/buttons'
import ExportData from 'exportReports/ExportData'
import { BillingPlanColumnHeader, BillingPlanPageColumnIds } from '../utils/BillingPlansUtils'
import BillingPlanCrudForm, { BillingPlanFormType } from './BillingPlanCrudForm'
import { BillingPlanReportColumns } from '../export/BillingPlanReportFormat'

const AddBillingPlanButton = AddEntryInTableButton
interface BillingPlanPageTableProps {
  tableData?: iBillingPlan[]
  isLoading: boolean
  filterValues: IFilterValues[]
  updateFilteredData: (data: iBillingPlan[]) => void
  onShowFilter: () => void
  //   onFilterFormSubmit: (filterValues: IFilterValues[]) => void
  onFilterFormReset: () => void
}

export default function BillingPlansPageTable(props: BillingPlanPageTableProps) {
  const {
    tableData,
    filterValues,
    isLoading,
    updateFilteredData,
    onShowFilter,
    onFilterFormReset,
  } = props

  const [typeOfCrud, setTypeOfCrud] = useState<string>()
  const [billingPlan, setBillingPlan] = useState<iBillingPlan>()
  const [showExportDialog, setShowExportDialog] = useState(false)

  const tableRef = React.useRef<Table<iBillingPlan>>(null)
  const sortedData = tableRef.current?.getSortedRowModel().rows.map((row) => row.original) ?? []

  const tableColumns = useMemo(
    () =>
      (!isLoading
        ? [
            {
              id: BillingPlanPageColumnIds.name,
              header: BillingPlanColumnHeader[BillingPlanPageColumnIds.name],
              accessorKey: BillingPlanPageColumnIds.name,
              cell: (info: CellContext<iBillingPlan, unknown>) => {
                const value: string = info.getValue() as string
                return (
                  <Tooltip
                    tooltipText={value}
                    toolTipClass='tooltip right-0 z-50 w-fit'
                    showOnlyWhenTextIsTruncate
                    id={`billing-plan-${info.row.index}`}
                    className='w-full text-left text-2xs leading-5  text-ellipsis px-2'
                  >
                    {value}
                  </Tooltip>
                )
              },
              meta: {},
              enableHiding: false,
              filterFn: 'includesString',
            },
            {
              id: BillingPlanPageColumnIds?.billingPlanType,
              header: BillingPlanColumnHeader[BillingPlanPageColumnIds?.billingPlanType],
              accessorKey: BillingPlanPageColumnIds?.billingPlanType,
              cell: (info: CellContext<iBillingPlan, unknown>) => {
                const value: string = info.getValue() as string
                return (
                  <div className='text-center text-2xs leading-5  text-ellipsis pr-2'>
                    {BillingPlanEnums[value]}
                  </div>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },

            // {
            //   id: BillingPlanPageColumnIds.numberOfApiKeys,
            //   header: BillingPlanColumnHeader[BillingPlanPageColumnIds.numberOfApiKeys],
            //   accessorKey: BillingPlanPageColumnIds.numberOfApiKeys,
            //   cell: (info: CellContext<iBillingPlan, unknown>) => {
            //     const value: string = info.getValue() as string
            //     return (
            //       <div className='text-center text-2xs leading-5  text-ellipsis px-2'>{value}</div>
            //     )
            //   },
            //   meta: {},
            //   filterFn: 'includesString',
            // },
            {
              id: BillingPlanPageColumnIds.numberOfDevices,
              header: BillingPlanColumnHeader[BillingPlanPageColumnIds.numberOfDevices],
              accessorKey: BillingPlanPageColumnIds.numberOfDevices,
              cell: (info: CellContext<iBillingPlan, unknown>) => {
                const value: string = info.getValue() as string
                return (
                  <div className='text-center text-2xs leading-5  text-ellipsis px-2'>{value}</div>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },
            {
              id: BillingPlanPageColumnIds.numberOfOperators,
              header: BillingPlanColumnHeader[BillingPlanPageColumnIds.numberOfOperators],
              accessorKey: BillingPlanPageColumnIds.numberOfOperators,
              cell: (info: CellContext<iBillingPlan, unknown>) => {
                const value: string = info.getValue() as string
                return (
                  <div className='text-center text-2xs leading-5  text-ellipsis px-2'>{value}</div>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },
            {
              id: BillingPlanPageColumnIds.numberOfUsers,
              header: BillingPlanColumnHeader[BillingPlanPageColumnIds.numberOfUsers],
              accessorKey: BillingPlanPageColumnIds.numberOfUsers,
              cell: (info: CellContext<iBillingPlan, unknown>) => {
                const value: string = info.getValue() as string
                return (
                  <div className='text-center text-2xs leading-5  text-ellipsis px-2'>{value}</div>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },
            {
              id: BillingPlanPageColumnIds.status,
              header: BillingPlanColumnHeader[BillingPlanPageColumnIds.status],
              accessorKey: BillingPlanPageColumnIds.status,
              cell: (info: CellContext<iBillingPlan, unknown>) => {
                const value: string = info.getValue() as string

                return (
                  <div
                    className='w-full h-full content-center cursor-pointer m-auto flex items-center justify-start'
                    title='Double click to view device details'
                  >
                    <div
                      className={clsx(
                        {
                          'bg-c-green': value === 'Active',
                          'bg-c-red-light': value === 'InActive',
                        },
                        'font-semibold whitespace-nowrap rounded-full text-c-dark-blue-1 bg-opacity-20 text-xs leading-4 px-2.5 py-1 ',
                      )}
                    >
                      {value === 'Active' && 'Active'}
                      {value === 'InActive' && 'Inactive'}
                    </div>
                  </div>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },
            {
              id: '999',
              cell: (info: CellContext<iBillingPlan, unknown>) => (
                <button
                  type='button'
                  title='Edit Billing Plan'
                  className='bg-c-dark-blue-1 rounded-full p-1.5 '
                  onClick={() => {
                    setTypeOfCrud('edit')
                    setBillingPlan(info.row.original)
                  }}
                >
                  <EditIcon height={24} width={24} fill='white' />
                </button>
              ),
              meta: {
                cellClassName: 'overflow-visible flex justify-end w-full px-2.5',
                headerClassName: 'w-1 overflow-visible',
                shouldDraggable: false,
                resizable: false,
                sortable: false,
              },
            },
          ]
        : []) as ColumnDef<iBillingPlan>[],
    [isLoading],
  )
  const renderFilterSummaryComponent = () => <> </>

  return (
    <>
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog}
        data={sortedData}
        reportColumns={BillingPlanReportColumns}
        widgetForReport={[]}
        reportTitle='Billing Plans'
        filterSummary=''
      />
      <TsTable
        ref={tableRef}
        columns={tableColumns}
        data={tableData ?? []}
        dataIsLoading={isLoading}
        showGlobalActionButton
        showGlobalFilter
        onExport={() => {}}
        onPrint={() => {}}
        getRowCanExpand={() => true}
        updateFilteredData={(data) => updateFilteredData(data)}
        renderFilterSummary={renderFilterSummaryComponent}
        resetFilter={() => onFilterFormReset()}
        onShowFilter={() => onShowFilter()}
        filterValues={filterValues}
        defaultSortedColumKey={BillingPlanPageColumnIds.name}
        setShowExportDialog={(val) => setShowExportDialog(val)}
        showFilterForm={false}
        globalActionButton={
          <AddBillingPlanButton
            buttonText='Add Billing Plan'
            buttonAction={() => setTypeOfCrud('add')}
          />
        }
        // setFilterValues={setFilterValues}
        // dateRangeSelectorType={DateRangeSelectorTypes.DateRange}
        // dateRangeOptions={dateRangeOptions}
        // dateRangeFilterFn={DateRangeColumnFilterFn<iFaultData>}
      />
      <Show>
        <Show.When isTrue={Boolean(typeOfCrud)}>
          <BillingPlanCrudForm
            type={typeOfCrud}
            close={() => {
              setTypeOfCrud(undefined)
              setBillingPlan(undefined)
            }}
            data={
              typeOfCrud === 'add'
                ? undefined
                : ({
                    ...billingPlan,
                    billingPlanType: {
                      value: billingPlan?.billingPlanType,
                      label: BillingPlanEnums[billingPlan?.billingPlanType as string],
                    },
                    status: {
                      value: billingPlan?.status,
                      label: billingPlan?.status === 'Active' ? 'Active' : 'Inactive',
                    },
                  } as unknown as BillingPlanFormType)
            }
          />
        </Show.When>
      </Show>
    </>
  )
}

BillingPlansPageTable.defaultProps = {
  tableData: [],
}

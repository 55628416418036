import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react'
// import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { useMsal } from '@azure/msal-react'
import clsx from 'clsx'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { dateRangeOptions, selectAllOption, selectBlankOption } from 'forms/FormUtils'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { DateRangeSelectorTypes, DeviceSyncFilterValues } from 'utils/CommonEnums'
import { useDeviceList } from 'data/DeviceListHook'
import { getDeviceListStatsData } from 'data/DeviceListStatsHook'
import BusinessAndCustomerSelection from 'components/modules/BusinessAndCustomerSelection'
import { BusinessContext } from 'contexts/BusinessContext'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { AxiosError } from 'axios'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { deviceListStatsQueryKey } from 'services/apis/caching/device'
import { sub } from 'date-fns'
import { iDeviceListDataWithStats, updateDeviceListDataWithStats } from '../data/DevicePageData'
import DevicePageTable from './DevicePageTable'
import {
  DevicePageColumnIds,
  DevicePageParamLabels,
  DevicePageFilterFormType,
} from './DevicesPageUtils'
import DeviceCountByTypeWidget from './widgets/DeviceCountByTypeWidget'
import DeviceCountByComplianceWidget from './widgets/DeviceCountByComplianceWidget'
import DeviceCountByGasTypeWidget from './widgets/DeviceCountByGasTypeWidget'
import DevicePageFilterForm from './DevicePageFilterForm'
import DeviceFilterFormat from '../export/DeviceFilterFormat'
// import DevicePageFilterForm from './DevicePageFilterForm'

function DevicesPageComponent() {
  useEffect(() => {
    document.title = 'Devices'
  }, [])

  // const appInsights = new ApplicationInsights({
  //   config: {
  //     connectionString:
  //       'InstrumentationKey=4be37327-059a-4816-9a8b-5c42f178ff26;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
  //   },
  // })
  // appInsights.loadAppInsights()

  const msalContext = useMsal()
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  // useEffect(() => {
  //   if (userInfo) {
  //     appInsights.trackPageView()
  //     const { user } = userInfo
  //     const { id: userId } = user || ''
  //     const { customerId } = user || ''
  //     appInsights.trackEvent({
  //       name: 'Device-Page-Event',
  //       properties: {
  //         userId,
  //         customerId,
  //       },
  //     })
  //   }
  // }, [userInfo])
  /**
   * Business Units List and Customer List from BusinessContext
   */
  const {
    customerDataFromQuery,
    businessUnitDataFromQuery,
    selectedBusinessUnit,
    selectedCustomer,
  } = useContext(BusinessContext)

  const businessUnitsList = businessUnitDataFromQuery?.data ?? []
  const customerList = customerDataFromQuery?.data ?? []
  const selectedCustomerParam = selectedCustomer?.id ?? ''
  const selectedMainBUParam = selectedBusinessUnit?.id ?? ''

  const isBusinessUnitsListLoading = businessUnitDataFromQuery?.isLoading
  const isBusinessUnitsListError = businessUnitDataFromQuery?.isError

  const [showFilterForm, setShowFilterForm] = useState(false)

  // global filter params
  const [searchParams, setSearchParams] = useSearchParams()

  // filter form params
  const childBUParam = searchParams.get(DevicePageParamLabels.businessUnit) ?? ''
  const deviceSerialParam = searchParams.get(DevicePageParamLabels.serialNumber) ?? ''
  const assetNumberParam = searchParams.get(DevicePageParamLabels.assetNumber) ?? ''
  const deviceStatusParam = searchParams.get(DevicePageParamLabels.status) ?? ''
  const deviceTypeParam = searchParams.get(DevicePageParamLabels.deviceType) ?? ''
  const deviceUserParam = searchParams.get(DevicePageParamLabels.deviceUser) ?? ''
  const gasTypeParam = searchParams.get(DevicePageParamLabels.gasType) ?? ''
  const lastSyncSelectorParam =
    searchParams.get(DevicePageParamLabels.lastSyncSelector) ?? DateRangeSelectorTypes.All
  const lastSyncDateToParam = searchParams.get(DevicePageParamLabels.lastsyncTo) ?? ''
  const lastSyncDateFromParam = searchParams.get(DevicePageParamLabels.lastsyncFrom) ?? ''

  const lastSWOnSelectorParam =
    searchParams.get(DevicePageParamLabels.lastSwitchOnSelector) ?? DateRangeSelectorTypes.All
  const lastSWOnDateTo = searchParams.get(DevicePageParamLabels.lastSwitchedOnTo) ?? ''
  const lastSWOnDateFrom = searchParams.get(DevicePageParamLabels.lastSwitchedOnFrom) ?? ''

  const [filteredDevicePageData, setFilteredDevicePageData] = useState<iDeviceListDataWithStats[]>(
    [],
  )
  const [unfilteredDevicePageData, setUnfilteredDevicePageData] = useState<
    iDeviceListDataWithStats[]
  >([])

  const { genericEventHandler } = useGenericEventHandler()
  const {
    data: deviceListData,
    isLoading: isDeviceListDataLoading,
    isError: isDeviceListDataError,
    error: deviceListDataError,
  } = useDeviceList(
    redirectPageURL,
    selectedMainBUParam,
    selectedCustomerParam,
    businessUnitsList,
    businessUnitsList !== undefined,
  )

  const {
    data: deviceListStatData,
    isLoading: isDeviceListStatLoading,
    isError: isDeviceListStatDataError,
    error: deviceListStatDataError,
  } = useQuery(
    deviceListStatsQueryKey(selectedMainBUParam),
    () =>
      getDeviceListStatsData({
        businessUnitId: selectedMainBUParam,
        msalContext,
        redirectPageURL,
      }),
    {
      enabled: !isBusinessUnitsListLoading && !isBusinessUnitsListError,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )

  useEffect(() => {
    if (isDeviceListDataError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: deviceListDataError?.message || 'Error getting device list data',
        error: deviceListDataError,
        extraData: {
          component: 'DevicePage',
          action: ' getting device list data',
        },
      })
    }

    if (isDeviceListStatDataError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: deviceListStatDataError?.message || 'Error getting device list stats data',
        error: deviceListStatDataError,
        extraData: {
          component: 'DevicePage',
          action: ' getting device list stats data',
        },
      })
    }
  }, [
    isDeviceListDataError,
    deviceListDataError,
    isDeviceListStatDataError,
    deviceListStatDataError,
  ])

  useEffect(() => {
    let deviceListDataWithStats = []
    if (!isDeviceListDataLoading && !isDeviceListStatLoading) {
      deviceListDataWithStats = updateDeviceListDataWithStats(
        deviceListData ?? [],
        deviceListStatData ?? [],
      )

      setUnfilteredDevicePageData(deviceListDataWithStats)
    }
  }, [deviceListData, deviceListStatData, isDeviceListDataLoading, isDeviceListStatLoading])

  const resetFilterFormParams = () => {
    searchParams.delete(DevicePageParamLabels.serialNumber)
    searchParams.delete(DevicePageParamLabels.deviceType)
    searchParams.delete(DevicePageParamLabels.businessUnit)
    searchParams.delete(DevicePageParamLabels.assetNumber)
    searchParams.delete(DevicePageParamLabels.status)
    searchParams.delete(DevicePageParamLabels.deviceUser)
    searchParams.delete(DevicePageParamLabels.lastsyncFrom)
    searchParams.delete(DevicePageParamLabels.lastsyncTo)
    searchParams.delete(DevicePageParamLabels.lastSwitchedOnFrom)
    searchParams.delete(DevicePageParamLabels.lastSwitchedOnTo)
    searchParams.delete(DevicePageParamLabels.lastSyncSelector)
    searchParams.delete(DevicePageParamLabels.lastSwitchOnSelector)
    searchParams.delete(DevicePageParamLabels.gasType)
    setSearchParams(searchParams)
  }
  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      console.log('Filter form submit handler in main page')
      formData.forEach((filter) => {
        if (filter.columnId === DevicePageColumnIds.gasType) {
          if (filter?.value === '') {
            searchParams.delete(DevicePageParamLabels.gasType)
          } else {
            searchParams.set(DevicePageParamLabels.gasType, filter?.value as string)
          }
        }

        if (filter.columnId === DevicePageColumnIds.serialNumber) {
          if (filter?.value === '') {
            searchParams.delete(DevicePageParamLabels.serialNumber)
          } else {
            searchParams.set(DevicePageParamLabels.serialNumber, filter?.value as string)
          }
        }

        if (filter.columnId === DevicePageColumnIds.assetNumber) {
          if (filter?.value === '') {
            searchParams.delete(DevicePageParamLabels.assetNumber)
          } else {
            searchParams.set(DevicePageParamLabels.assetNumber, filter?.value as string)
          }
        }

        if (filter.columnId === DevicePageColumnIds.deviceStatus) {
          if (filter?.value === '') {
            searchParams.delete(DevicePageParamLabels.status)
          } else {
            searchParams.set(DevicePageParamLabels.status, filter?.value as string)
          }
        }

        if (filter.columnId === DevicePageColumnIds.deviceUser) {
          if (filter?.value === '') {
            searchParams.delete(DevicePageParamLabels.deviceUser)
          } else {
            const deviceUser = filter?.value as IselectOptionProps
            const deviceUserVal = deviceUser.value as string
            const deviceUserLabel = deviceUser.label as string

            if (deviceUserVal === '') {
              searchParams.delete(DevicePageParamLabels.deviceUser)
            } else {
              searchParams.set(DevicePageParamLabels.deviceUser, deviceUserLabel as string)
            }
          }
        }

        if (filter.columnId === DevicePageColumnIds.deviceType) {
          if (filter?.value === '') {
            searchParams.delete(DevicePageParamLabels.deviceType)
          } else {
            searchParams.set(DevicePageParamLabels.deviceType, filter?.value as string)
          }
        }

        if (filter.columnId === DevicePageColumnIds.businessUnit) {
          if (filter?.value === '') {
            searchParams.delete(DevicePageParamLabels.businessUnit)
          } else {
            searchParams.set(DevicePageParamLabels.businessUnit, filter?.value as string)
          }
        }

        // lastSyncSelector: 'lsync',
        // lastsyncFrom: 'lsyncfrom',
        // lastsyncTo: 'lsyncto',

        // lastSwitchOnSelector: 'lson',
        // lastSwitchedOnFrom: 'lsonfrom',
        // lastSwitchedOnTo: 'lsonto',
        if (filter.columnId === DevicePageColumnIds.lastSync) {
          if (filter?.value === '') {
            searchParams.delete(DevicePageParamLabels.lastsyncFrom)
            searchParams.delete(DevicePageParamLabels.lastsyncTo)
            searchParams.delete(DevicePageParamLabels.lastSyncSelector)
          } else {
            // const filterValue = filter?.value as ISelectOptionProps
            const filterValuedateRangeProps = filter?.value as DateRange
            if (filterValuedateRangeProps && filterValuedateRangeProps.isCustom) {
              console.log('Date range is custom')
              const customSyncFilterValue = filterValuedateRangeProps.customValue
              if (customSyncFilterValue) {
                searchParams.set(DevicePageParamLabels.lastSyncSelector, customSyncFilterValue)
              }
            } else {
              const lastSyncdateRangeToBeFiltered = (filter?.value as IselectOptionProps)
                .value as DateRange
              const lastSyncSelectorTobeFiltered = (filter?.value as IselectOptionProps)
                .label as string
              searchParams.set(DevicePageParamLabels.lastSyncSelector, lastSyncSelectorTobeFiltered)

              searchParams.set(
                DevicePageParamLabels.lastsyncFrom,
                (lastSyncdateRangeToBeFiltered.startDate as Date).toISOString().split('T')[0],
              )
              searchParams.set(
                DevicePageParamLabels.lastsyncTo,
                (lastSyncdateRangeToBeFiltered.endDate as Date).toISOString().split('T')[0],
              )
            }
          }
        }

        if (filter.columnId === DevicePageColumnIds.lastSwitchOn) {
          if (filter?.value === '') {
            searchParams.delete(DevicePageParamLabels.lastSwitchedOnFrom)
            searchParams.delete(DevicePageParamLabels.lastSwitchedOnTo)
            searchParams.delete(DevicePageParamLabels.lastSwitchOnSelector)
          } else {
            const lastSwOndateRangeToBeFiltered = (filter?.value as IselectOptionProps)
              .value as DateRange
            const lastSwOnSelectorTobeFiltered = (filter?.value as IselectOptionProps)
              .label as string
            searchParams.set(
              DevicePageParamLabels.lastSwitchOnSelector,
              lastSwOnSelectorTobeFiltered,
            )
            searchParams.set(
              DevicePageParamLabels.lastSwitchedOnFrom,
              (lastSwOndateRangeToBeFiltered.startDate as Date).toISOString().split('T')[0],
            )
            searchParams.set(
              DevicePageParamLabels.lastSwitchedOnTo,
              (lastSwOndateRangeToBeFiltered.endDate as Date).toISOString().split('T')[0],
            )
          }
        }
      })

      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const handleFilterFormReset = () => {
    // console.log('Reset form')
    resetFilterFormParams()
  }

  const filterTableValues = useMemo(
    () => [
      {
        columnId: DevicePageColumnIds.serialNumber,
        value: deviceSerialParam ?? '',
      },
      {
        columnId: DevicePageColumnIds.deviceType,
        value: deviceTypeParam ?? '',
      },
      {
        columnId: DevicePageColumnIds.assetNumber,
        value: assetNumberParam ?? '',
      },
      {
        columnId: DevicePageColumnIds.deviceStatus,
        value: deviceStatusParam ?? '',
      },
      {
        columnId: DevicePageColumnIds.businessUnit,
        value: childBUParam ?? '',
      },
      {
        columnId: DevicePageColumnIds.deviceUser,
        value: deviceUserParam ?? '',
      },
      {
        columnId: DevicePageColumnIds.gasType,
        value: gasTypeParam ?? '',
      },
      {
        columnId: DevicePageColumnIds.lastSync,
        value:
          lastSyncSelectorParam === DeviceSyncFilterValues.AllSynced
            ? {
                startDate: new Date(),
                endDate: new Date(),
                isCustom: true,
                customValue: DeviceSyncFilterValues.AllSynced,
              }
            : lastSyncSelectorParam === DeviceSyncFilterValues.NotSynced
            ? {
                startDate: new Date(),
                endDate: new Date(),
                isCustom: true,
                customValue: DeviceSyncFilterValues.NotSynced,
              }
            : lastSyncDateFromParam === '' && lastSyncDateToParam === ''
            ? ''
            : {
                startDate: new Date(lastSyncDateFromParam),
                endDate: new Date(lastSyncDateToParam),
              },
      },
      {
        columnId: DevicePageColumnIds.lastSwitchOn,
        value:
          lastSWOnDateFrom === '' && lastSWOnDateTo === ''
            ? ''
            : {
                startDate: new Date(lastSWOnDateFrom),
                endDate: new Date(lastSWOnDateTo),
              },
      },
    ],
    [
      deviceSerialParam,
      deviceTypeParam,
      childBUParam,
      assetNumberParam,
      deviceStatusParam,
      deviceUserParam,
      gasTypeParam,
      lastSyncSelectorParam,
      lastSyncDateFromParam,
      lastSyncDateToParam,
      lastSWOnSelectorParam,
      lastSWOnDateFrom,
      lastSWOnDateTo,
    ],
  )

  const convertISelectOptionPropsToServiceFaultsFormType = useMemo(() => {
    const serialNumberFiltered =
      filterTableValues.find((filter) => filter.columnId === DevicePageColumnIds.serialNumber)
        ?.value ?? ''
    const assetNumberFiltered = filterTableValues.find(
      (filter) => filter.columnId === DevicePageColumnIds.assetNumber,
    )?.value as string
    const deviceStatusFiltered = filterTableValues.find(
      (filter) => filter.columnId === DevicePageColumnIds.deviceStatus,
    )?.value as string
    const deviceUserFiltered = filterTableValues.find(
      (filter) => filter.columnId === DevicePageColumnIds.deviceUser,
    )?.value as string
    const deviceTypeFiltered =
      filterTableValues.find((filter) => filter.columnId === DevicePageColumnIds.deviceType)
        ?.value ?? ''
    const businessUnitFiltered =
      filterTableValues.find((filter) => filter.columnId === DevicePageColumnIds.businessUnit)
        ?.value ?? ''
    const gasTypeFiltered = filterTableValues.find(
      (filter) => filter.columnId === DevicePageColumnIds.gasType,
    )?.value as string

    // let lastSyncDateRangeFiltered
    // const lsVal = filterTableValues.find(
    //   (filter) => filter.columnId === DevicePageColumnIds.lastSync,
    // )?.value

    const lastSyncDateRangeFiltered = filterTableValues.find(
      (filter) => filter.columnId === DevicePageColumnIds.lastSync,
    )?.value as DateRange

    const lastSWOnDateRangeFiltered = filterTableValues.find(
      (filter) => filter.columnId === DevicePageColumnIds.lastSwitchOn,
    )?.value as DateRange

    const r: DevicePageFilterFormType = {
      serialNumber: {
        value: serialNumberFiltered as string,
        label: serialNumberFiltered as string,
      },
      deviceType:
        deviceTypeFiltered === ''
          ? selectAllOption
          : {
              value: deviceTypeFiltered as string,
              label: deviceTypeFiltered as string,
            },
      assetNumber: {
        value: assetNumberFiltered as string,
        label: assetNumberFiltered as string,
      },

      businessUnit:
        businessUnitFiltered === ''
          ? selectAllOption
          : {
              value: businessUnitFiltered as string,
              label: businessUnitFiltered as string,
            },

      deviceStatus:
        deviceStatusFiltered === ''
          ? selectAllOption
          : {
              value: deviceStatusFiltered as string,
              label: deviceStatusFiltered as string,
            },

      deviceUser:
        deviceUserFiltered === ''
          ? selectBlankOption
          : {
              value: deviceUserFiltered as string,
              label: deviceUserFiltered as string,
            },

      gasType:
        gasTypeFiltered === ''
          ? selectAllOption
          : {
              value: gasTypeFiltered as string,
              label: gasTypeFiltered as string,
            },

      lastSync: lastSyncDateRangeFiltered
        ? {
            startDate: new Date(lastSyncDateRangeFiltered.startDate), // new Date(faultDateTimeFromParam as string),
            endDate: new Date(lastSyncDateRangeFiltered.endDate), // new Date(faultDateTimeToParam as string),
          }
        : {
            startDate: sub(new Date(), { months: 6 }),
            endDate: new Date(),
          },

      lastSwitchOn: lastSWOnDateRangeFiltered
        ? {
            startDate: new Date(lastSWOnDateRangeFiltered.startDate), // new Date(faultDateTimeFromParam as string),
            endDate: new Date(lastSWOnDateRangeFiltered.endDate), // new Date(faultDateTimeToParam as string),
          }
        : {
            startDate: sub(new Date(), { months: 6 }),
            endDate: new Date(),
          },

      lastSyncSelector:
        lastSyncSelectorParam === DeviceSyncFilterValues.AllSynced
          ? {
              label: DeviceSyncFilterValues.AllSynced,
              value: DeviceSyncFilterValues.AllSynced,
            }
          : lastSyncSelectorParam === DeviceSyncFilterValues.NotSynced
          ? {
              label: DeviceSyncFilterValues.NotSynced,
              value: DeviceSyncFilterValues.NotSynced,
            }
          : lastSyncSelectorParam !== ''
          ? dateRangeOptions.find((option) => option.label === lastSyncSelectorParam) ?? {
              label: '',
              value: '',
            }
          : dateRangeOptions.find((option) => option.label === DateRangeSelectorTypes.All) ?? {
              label: '',
              value: '',
            },

      lastSwitchOnSelector:
        lastSWOnSelectorParam !== ''
          ? dateRangeOptions.find((option) => option.label === lastSWOnSelectorParam) ?? {
              label: '',
              value: '',
            }
          : dateRangeOptions.find((option) => option.label === DateRangeSelectorTypes.All) ?? {
              label: '',
              value: '',
            },
    }

    return r
  }, [filterTableValues, dateRangeOptions])

  // const [isDivVisible, setIsDivVisible] = useState(true)

  // useEffect(() => {
  //   const handleScroll = debounce(() => {
  //     const scrollPosition = window.scrollY
  //     const threshold = 220 // Adjust this value to set the scroll position threshold
  //     if (scrollPosition < threshold ) {
  //       setIsDivVisible(true)
  //     } else if (scrollPosition > threshold + 10 && isDivVisible) { // Adjust the threshold - 50 value to set the scroll position for becoming visible again
  //       setIsDivVisible(false)
  //     }
  //   }, 200) // Adjust the debounce delay (in milliseconds) as needed

  //   handleScroll()

  //   window.addEventListener('scroll', handleScroll)

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])

  useEffect(() => {
    function hideFilterFormHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && showFilterForm) {
        setShowFilterForm(false)
      }
    }
    window.addEventListener('keyup', hideFilterFormHandler)

    return () => {
      window.removeEventListener('keyup', hideFilterFormHandler)
    }
  }, [showFilterForm])
  return (
    <>
      <BusinessAndCustomerSelection pageTitle='Devices' />
      <div
        id='device-page-main'
        aria-hidden='true'
        onClick={() => showFilterForm && setShowFilterForm(false)}
        className='flex flex-col w-full h-[calc(100vh-80px)] '
      >
        <div
          id='device-page-widgets'
          className='bg-c-light-blue-2 px-3  md:px-5 py-3 md:py-4 gap-x-4 gap-y-2 grid grid-cols-1 xs:grid-cols-3 '
        >
          <div className='h-full'>
            <DeviceCountByTypeWidget
              deviceListData={unfilteredDevicePageData ?? []}
              onFilter={(filterRequestData: IFilterValues[]) =>
                handleFilterFormSubmit(filterRequestData)
              }
              deviceTypeFilteredParam={
                (filterTableValues.find(
                  (filter) => filter.columnId === DevicePageColumnIds.deviceType,
                )?.value as string) ?? ''
              }
            />
          </div>
          <div className='h-full'>
            <DeviceCountByGasTypeWidget
              deviceListData={unfilteredDevicePageData ?? []}
              onFilter={(filterRequestData: IFilterValues[]) =>
                handleFilterFormSubmit(filterRequestData)
              }
              gasTypeFilteredParam={
                (filterTableValues.find((filter) => filter.columnId === DevicePageColumnIds.gasType)
                  ?.value as string) ?? ''
              }
            />
          </div>
          <div className='h-full'>
            <DeviceCountByComplianceWidget
              deviceListData={unfilteredDevicePageData ?? []}
              onFilter={(filterRequestData: IFilterValues[]) =>
                handleFilterFormSubmit(filterRequestData)
              }
              filteredParams={[
                (filterTableValues.find(
                  (filter) => filter.columnId === DevicePageColumnIds.deviceUser,
                )?.value as string) ?? '',
                (filterTableValues.find(
                  (filter) => filter.columnId === DevicePageColumnIds.lastSync,
                )?.value as string) ?? '',

                (filterTableValues.find(
                  (filter) => filter.columnId === DevicePageColumnIds.lastSync,
                )?.value === ''
                  ? ''
                  : (
                      filterTableValues.find(
                        (filter) => filter.columnId === DevicePageColumnIds.lastSync,
                      )?.value as DateRange
                    ).customValue) ?? '',
              ]}
            />
          </div>
        </div>
        <div id='device-page-table' className=' h-full'>
          <DevicePageTable
            tableData={unfilteredDevicePageData ?? []}
            isLoading={isDeviceListDataLoading || isDeviceListStatLoading}
            updateFilteredData={(filteredData: iDeviceListDataWithStats[]) =>
              setFilteredDevicePageData(filteredData)
            }
            filterValues={filterTableValues}
            onFilterFormSubmit={(filterVal: IFilterValues[]) => handleFilterFormSubmit(filterVal)}
            onFilterFormReset={() => handleFilterFormReset()}
            onShowFilter={() => setShowFilterForm(true)}
            filterSummary={DeviceFilterFormat(
              filterTableValues,
              customerList && selectedCustomerParam !== ''
                ? customerList?.find((c) => c.id === selectedCustomerParam)?.name || ''
                : customerList?.[0]?.name || '',
              businessUnitsList && selectedMainBUParam !== ''
                ? businessUnitsList?.find((c) => c.id === selectedMainBUParam)?.name || ''
                : businessUnitsList?.[0]?.name || '',
            )}
          />
        </div>
      </div>

      <div
        id='device-page-filter-form'
        className={clsx(
          'filter-form hideScrollBar',
          showFilterForm ? 'z-50 -ml-80 opacity-100' : 'opacity-0 -z-50',
        )}
      >
        <DevicePageFilterForm
          selectedFilterValues={convertISelectOptionPropsToServiceFaultsFormType}
          optionsData={filteredDevicePageData}
          onFilterSubmit={(formData: IFilterValues[]) => handleFilterFormSubmit(formData)}
          onFilterReset={() => handleFilterFormReset()}
          onFilterFormClose={() => setShowFilterForm(false)}
        />
      </div>
    </>
  )
}

const DevicesPage = withErrorBoundary(DevicesPageComponent, {
  FallbackComponent: ErrorPage,
})
export default withPageTracking(DevicesPage as React.ComponentType<unknown>)

// -ml-80 -mt-[272px]

// useEffect(() => {
//   if (businessUnitsList && businessUnitsList.length > 0) {
//     if (selectedCustomerParam !== selectAllOption.value && isGlobalUser) {
//       setMainBUParam(businessUnitsList[1].value as string)
//       // searchParams.set('mainbu', businessUnitsList[1].value as string)
//       // // Disable ALL option for main BU
//       // const AllOptionIndex = businessUnitsList.findIndex(
//       //   (bu) => bu.value === selectAllOption.value,
//       // )
//       // if (AllOptionIndex) {
//       //   businessUnitsList[AllOptionIndex] = {
//       //     ...businessUnitsList[AllOptionIndex],
//       //     isDisabled: true,
//       //   }
//       // }
//       // businessUnitsList.shift()
//       // businessUnitsList.unshift({...selectAllOption, isDisabled: true})
//     } else {
//       setMainBUParam(selectAllOption.value as string)
//       // searchParams.set('mainbu', selectAllOption.value as string)
//     }
//     // setSearchParams(searchParams)
//   }
// }, [businessUnitsList, selectedCustomerParam])

// const [faultsByDeviceType, setFaultsByDeviceType] = useState<iWidgetData[] | never>()
// const [faultsByFaultType, setFaultsByFaultType] = useState<iWidgetData[] | never>()
// const [faultsInXDays, setFaultsInXDays] = useState<iWidgetData[] | never>()

// useEffect(() => {
//   if (unfilteredDevicePageData) {
//     const serialNumbersInData: IselectOptionProps[] = filteredDevicePageData?.map((device) => ({
//       value: device.serialNumber,
//       label: device.serialNumber,
//     }))
//     // remove duplicate serial numbers

//     const uniqueSerialNumbers = new Set(serialNumbersInData.map((item) => item.value))
//     const serialNumbersInDataUnique = Array.from(uniqueSerialNumbers).map((item) => ({
//       value: item,
//       label: item?.toString(),
//     }))

//     setSerialNumberOptions(serialNumbersInDataUnique as IselectOptionProps[])

//     const businessUnitsInData: IselectOptionProps[] | undefined = filteredDevicePageData?.map(
//       (device) => ({
//         value: device.businessUnit.id,
//         label: device.businessUnit.name,
//       }),
//     )
//     // remove duplicates in business units

//     const businessUnitsInDataUnique: IselectOptionProps[] = businessUnitsInData.filter(
//       (v, i, a) => a.findIndex((t) => t.value === v.value) === i,
//     )

//     businessUnitsInDataUnique.unshift(
//       selectAllOption as {
//         value: string
//         label: string
//       },
//     )
//     setBusinessUnitOptions(businessUnitsInDataUnique as IselectOptionProps[])

//     const deviceTypesInData: IselectOptionProps[] =
//       PrepareDeviceTypeOptionsForSelect(filteredDevicePageData)
//     setDeviceTypeOptions(deviceTypesInData)

//     const deviceUsersInData: IselectOptionProps[] = filteredDevicePageData?.map(
//       (device) =>
//         device.deviceUser && {
//           value: device.deviceUser?.deviceUserId ?? '',
//           label: device.deviceUser?.fullName ?? '',
//         },
//     )

//     // remove duplicate fault types
//     const deviceUsersInDataUnique: IselectOptionProps[] = deviceUsersInData?.filter(
//       (v, i, a) => a.findIndex((t) => t?.value === v?.value) === i,
//     )

//     deviceUsersInDataUnique.unshift(
//       selectAllOption as {
//         value: string
//         label: string
//       },
//     )
//     setDeviceUsersOptions(deviceUsersInDataUnique as IselectOptionProps[])
//   }
// }, [unfilteredDevicePageData])

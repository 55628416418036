import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'

import { sub } from 'date-fns'

import { DateRangeSelectorTypes, SelectOptionTypes } from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'

import FilterFormTitle from 'components/formComponents/FilterFormTitle'
import { dateRangeOptions, selectAllOption, selectBlankOption } from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { DeviceEventLogFilterFormType, columnIDs } from './DeviceEventLogs'

interface DeviceEventLogFilterFormProps {
  // Define any props that this component will receive
  selectedValues?: DeviceEventLogFilterFormType
  deviceUsersData?: readonly IselectOptionProps[]
  eventDescriptionsData?: readonly IselectOptionProps[]

  // Define any functions that this component will call
  onFilterSubmit: (values: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

const defaultFilterValues = () => {
  const dtTo = new Date()
  const dtFrom = sub(new Date(), { months: 6 })
  const defValues: DeviceEventLogFilterFormType = {
    eventDateSelector: dateRangeOptions.find(
      (option) => option.value === DateRangeSelectorTypes.Custom,
    ) ?? {
      label: '',
      value: '',
    },
    eventDate: {
      startDate: dtFrom,
      endDate: dtTo,
    },
    eventDescription: selectBlankOption,
    eventDeviceUser: selectBlankOption,
  }
  return defValues
}

function DeviceEventLogFilterForm(props: DeviceEventLogFilterFormProps): JSX.Element {
  const {
    selectedValues,
    deviceUsersData,
    eventDescriptionsData,
    onFilterSubmit,
    onFilterReset,
    onFilterFormClose,
  } = props

  const defaultFormValues = defaultFilterValues()

  const { register, control, handleSubmit, setValue, reset, formState, watch } =
    useForm<DeviceEventLogFilterFormType>({
      defaultValues: defaultFormValues,
      values: selectedValues,
    })

  const watchEventDateSelector = watch('eventDateSelector')
  const eventDateStart = watch('eventDate.startDate')
  const eventDateEnd = watch('eventDate.endDate')
  const [showCustomEventDate, setShowCustomEventDate] = React.useState<boolean>(false)

  useEffect(() => {
    if (watchEventDateSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomEventDate(true)
    } else {
      setShowCustomEventDate(false)
    }
  }, [watchEventDateSelector])

  const { errors, isDirty, isValid, isSubmitting } = formState

  const onSubmit: SubmitHandler<DeviceEventLogFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: columnIDs.eventDate,
      value: data.eventDateSelector.label === DateRangeSelectorTypes.All ? '' : data.eventDate,
    })

    filterValues.push({
      columnId: columnIDs.eventDescription,
      value:
        data.eventDescription === null ||
        data.eventDescription.value?.toString().toLowerCase() ===
          SelectOptionTypes.All.toLowerCase()
          ? ''
          : data.eventDescription.value || '',
    })

    filterValues.push({
      columnId: columnIDs.eventDeviceUser,
      value:
        data.eventDeviceUser.label.toString().toLowerCase() ===
        selectAllOption.label.toString().toLowerCase()
          ? ''
          : data.eventDeviceUser.value || '',
    })
    reset(data)
    onFilterSubmit(filterValues)
  }

  const onError = (errors: FieldErrors<DeviceEventLogFilterFormType>) => {
    console.log(errors)
  }

  const onReset = () => {
    reset(defaultFormValues)
    onFilterReset()
  }

  return (
    <div className='filter-form-container'>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button'
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit, onError)} onReset={onReset} noValidate className=''>
        <div className='filter-form-fields-container'>
          {/* // Event Date */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='eventDateSelector'
              className='pl-4 pr-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Date
            </label>

            <Controller
              name='eventDateSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,

                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('eventDate', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('eventDate', {
                          startDate: selectedValues?.eventDate.startDate || new Date(),
                          endDate: selectedValues?.eventDate.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>

          {showCustomEventDate && (
            <>
              <div className='flex flex-row justify-between items-end'>
                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start '>
                    <label
                      htmlFor='eventDateSelectorFrom'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
                    >
                      From
                    </label>
                    <div className=''>
                      <Controller
                        name='eventDate.startDate'
                        control={control}
                        //   rules={{
                        //     validate: (value) => {
                        //       if (isAfter(new Date(value), new Date(eventDateStart))) {
                        //         return 'Start date must be less than end date'
                        //       }
                        //       return true
                        //     },
                        //   }}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              maxDate: eventDateEnd,
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className='mx-0.5 w-4 h-4 my-3'>
                  <ChevronRightIcon className='self-center' />
                </div>

                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start'>
                    <label
                      htmlFor='eventDateFilterTo'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
                    >
                      To
                    </label>
                    <div className=''>
                      <Controller
                        name='eventDate.endDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              // field,
                              selected: field.value,
                              minDate: eventDateStart,
                              maxDate: new Date(),
                              onChange: (date: Date | null) => field.onChange(date as Date),
                              // selectsStart: true,
                              // startDate: field.value,
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {errors.eventDate && (
                <>
                  <p className='text-xs text-red-500'>{errors.eventDate?.startDate?.message}</p>
                  <p className='text-xs text-red-500'>{errors.eventDate?.endDate?.message}</p>
                </>
              )}
            </>
          )}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='eventLogDescFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Description
            </label>
            <Controller
              name='eventDescription'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: eventDescriptionsData,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.eventDescription?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='eventDeviceUserSelector'
              className='pl-4 pr-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Assigned To
            </label>

            <Controller
              name='eventDeviceUser'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: deviceUsersData,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
          </div>
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button disabled:filter-form-reset-button disabled:cursor-not-allowed'
            type='submit'
            value='Apply'
            disabled={!isDirty || isSubmitting || !isValid}
          />
        </div>
      </form>

      <DevTool control={control} />
    </div> // opening div
  )
}

export default withErrorBoundary(DeviceEventLogFilterForm, {
  FallbackComponent: ErrorPage,
})

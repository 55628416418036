import React from 'react'
import { iDeviceUser, iBusinessUnit } from 'data/DeviceListHook'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import {
  FormatToShortDate,
  FormatBusinessUnit,
  FormatDeviceUser,
} from 'exportReports/ExportDataUtils'

const DeviceReportColumns: ExportColumnType[] = [
  {
    key: 'serialNumber',
    label: 'Serial #',
    format: (val: string) => val,
    cellWidth: 100,
  },
  {
    key: 'deviceType',
    label: 'Device',
    format: (val: string) => val,
    cellWidth: 55,
  },
  {
    key: 'customerAssetNumber',
    label: 'Asset #',
    format: (val: string) => val,
    cellWidth: 65,
  },
  {
    key: 'businessUnit',
    label: 'Business Unit',
    format: (val: iBusinessUnit) => FormatBusinessUnit(val),
    cellWidth: 65,
  },

  {
    key: 'status',
    label: 'Status',
    format: (val: string) => val,
    cellWidth: 50,
  },
  {
    key: 'lastUploadDate',
    label: 'Synced',
    format: (val?: Date) => FormatToShortDate(val),
    cellWidth: 65,
  },
  {
    key: 'lastSwitchOnDate',
    label: 'Last On',
    format: (val?: Date) => FormatToShortDate(val),
    cellWidth: 65,
  },
  {
    key: 'deviceUser',
    label: 'Assigned',
    format: (val?: iDeviceUser) => FormatDeviceUser(val),
    cellWidth: 65,
  },
]

export { DeviceReportColumns }

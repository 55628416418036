import React from 'react'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { FormatUTXToDateTime, FormatBusinessUnit } from 'exportReports/ExportDataUtils'
import { AlarmsConstants } from 'utils/Constants'
import { AlarmTypes } from 'utils/CommonEnums'

const SafetyReportColumns: ExportColumnType[] = [
  {
    key: 'SerialNumber',
    label: 'Serial #',
    format: (val: string) => val,
    cellWidth: 80,
  },
  {
    key: 'TimeStamp',
    label: 'Date & Time',
    format: (val: any) => FormatUTXToDateTime(val as number),
    cellWidth: 65,
  },
  {
    key: 'AssetNumber',
    label: 'Asset No',
    format: (val: string) => val,
    cellWidth: 45,
  },
  {
    key: 'businessUnitName',
    label: 'Business Unit',
    format: (val: string) => val,
    cellWidth: 65,
  },
  {
    key: 'Gas',
    label: 'Gas',
    format: (val: string) => val,
    cellWidth: 35,
  },
  {
    key: 'alarm',
    label: 'Alarm',
    format: (val: string) => AlarmsConstants[val as AlarmTypes],
    cellWidth: 55,
  },
  {
    key: 'Level',
    label: '% level',
    format: (val: string) => val,
    cellWidth: 55,
  },
  {
    key: 'Alarm1SetPoint',
    label: 'Peak Value',
    format: (val: string) => val,
    cellWidth: 65,
  },
  {
    key: 'AssignedTo',
    label: 'Assigned To',
    format: (val: string) => val,
    cellWidth: 65,
  },
]

export { SafetyReportColumns }

import React, { memo, useEffect, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { iDevice } from 'data/DeviceListHook'
import clsx from 'clsx'
import { CalenderChildrenType } from 'components/modules/Calender/CalenderType'
import Tooltip from 'components/atom/Tooltip'
import { checkWeatherDeviceOverDue } from 'data/GenericDataUtils'
import { CalenderOverDueStatus } from '../../CalibrationsPageUtils'

type ZoomedCalibrationCalendarWidgetProps = {
  open: boolean
  onClose: () => void
  children: React.ReactNode
}

export const ZoomedCalibrationDataWidget = memo(({ event, day }: CalenderChildrenType) => {
  const { displayValue: overdueStatus } = checkWeatherDeviceOverDue(day.date) as {
    displayValue: keyof typeof CalenderOverDueStatus
    isOverDue: boolean
  }
  const devices = (event as iDevice[])?.reduce?.(
    (acc: { [key: string]: iDevice[] }, curr: iDevice) => {
      if (curr.deviceType && curr.calibrationDueDate) {
        return {
          ...acc,
          [curr.deviceType]: [...(acc[curr.deviceType] ?? []), curr].filter((d) => d),
        }
      }
      return acc
    },
    {},
  )

  const [show, setShow] = useState(false)
  const timer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    timer.current = setTimeout(() => setShow(true), 100)
    return () => timer.current && clearTimeout(timer.current)
  }, [])

  if (!event) return <div className='min-h-[50px] h-full min-w-[100px] w-full ' />

  return (
    <div className='min-h-[50px] h-full min-w-[100px] w-full'>
      <Transition
        show={show}
        enter='transition-opacity ease-in duration-150 delay-50'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity ease-in duration-200 delay-50'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className={clsx('gap-2 absolute top-1/2 -translate-y-1/2 right-2 z-10 grid', {
          'grid-cols-2 grid-rows-2': Object.values(devices).length > 2,
          'grid-cols-1 grid-rows-2': Object.values(devices).length <= 2,
        })}
      >
        {Object.entries(devices).map(([key, value]) => (
          <Tooltip
            tooltipText={key}
            toolTipClass='tooltip text-4xs w-max px-2 m-auto -top-7 left-1/2 -translate-x-1/2 text-center'
            className={clsx(
              CalenderOverDueStatus[overdueStatus],
              'rounded-full p-1 select-none relative',
            )}
            key={key}
          >
            <div className='text-2xs font-bold text-ellipsis'>
              {value.length > 99 ? `99+` : `${value.length}`.padStart(2, '0')}
            </div>
          </Tooltip>
        ))}
      </Transition>
    </div>
  )
})

ZoomedCalibrationDataWidget.displayName = 'ZoomedCalibrationDataWidget'

export default function ZoomedCalibrationCalendarWidget({
  open,
  onClose,
  children,
}: ZoomedCalibrationCalendarWidgetProps) {
  return (
    <Transition appear show={open}>
      <Dialog open={open} as='div' className='relative z-50 rounded-2xl' onClose={onClose}>
        <div className='fixed inset-0 bg-black/30' aria-hidden='true' />
        <div className='fixed inset-0 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center'>
            <Transition.Child
              enter='transition-all ease-in duration-150 delay-50'
              enterFrom='opacity-0 transform-[scale(0%)]'
              enterTo='opacity-100 transform-[scale(100%)]'
              leave='transition-all ease-in duration-200 delay-50'
              leaveFrom='opacity-100 transform-[scale(100%)]'
              leaveTo='opacity-0 transform-[scale(0%)]'
            >
              <Dialog.Panel className='rounded-2xl bg-white'>{children}</Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

import { iBillingPlan, useSubscription } from 'data/CustomerListHook'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import withRoleChecking from 'hoc/withRoleChecking'
import BillingPlansPageTable from '../components/BillingPlansPageTable'

function BillingPlans() {
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`
  const [showFilterForm, setShowFilterForm] = useState(false)
  const [filteredBillingPlans, setFilteredBillingPlans] = useState<iBillingPlan[]>()
  const { genericEventHandler } = useGenericEventHandler()

  useEffect(() => {
    document.title = 'Profile - Customers'
  }, [])

  useEffect(() => {
    function hideFilterFormHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && showFilterForm) {
        setShowFilterForm(false)
      }
    }
    window.addEventListener('keyup', hideFilterFormHandler)

    return () => {
      window.removeEventListener('keyup', hideFilterFormHandler)
    }
  }, [showFilterForm])

  const { data, isLoading, isError, error } = useSubscription(redirectPageURL, true)

  useEffect(() => {
    if (isError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error,
        message: error.message || 'Error while fetching Business List Data',
        extraData: {
          component: 'BillingPlans',
          action: 'useSubscription',
        },
      })
    }
  }, [error, isError])
  return (
    <div
      id='billing-plans-main'
      className='flex flex-col pt-3 h-full w-full'
      aria-hidden='true'
      onClick={() => showFilterForm && setShowFilterForm(false)}
    >
      <BillingPlansPageTable
        tableData={data}
        isLoading={isLoading}
        filterValues={[]}
        updateFilteredData={setFilteredBillingPlans}
        onShowFilter={() => {}}
        //   onFilterFormSubmit={() => {}}
        onFilterFormReset={() => {}}
      />
    </div>
  )
}
export default withRoleChecking(BillingPlans, [], true)

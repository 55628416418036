import { iBusinessUnit } from 'data/DeviceListHook'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { FormatBusinessUnit } from 'exportReports/ExportDataUtils'
import { OperatorsPageColumnIds } from '../view/OperatorsPageUtils'

const OperatorReportColumns: ExportColumnType[] = [
  {
    key: OperatorsPageColumnIds.fullName,
    label: 'Name',
    format: (val: string) => val,
    cellWidth: 150,
  },

  {
    key: OperatorsPageColumnIds.operatorId,
    label: 'ID',
    format: (val: string) => val,
    cellWidth: 130,
  },
  {
    key: OperatorsPageColumnIds.businessUnit,
    label: 'Business Unit',
    format: (val: iBusinessUnit) => FormatBusinessUnit(val),
    cellWidth: 130,
  },
  {
    key: OperatorsPageColumnIds.devicesInUse,
    label: 'Device(s) in use',
    format: (val?: number) => `${val}`,
    cellWidth: 60,
  },

  {
    key: OperatorsPageColumnIds.status,
    label: 'Status',
    format: (val: string) => (val === 'Active' ? 'Active' : 'Inactive'),
    cellWidth: 50,
  },
]

export { OperatorReportColumns }

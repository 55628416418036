import React from 'react'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { FormatBusinessUnit, FormatToShortDate } from 'exportReports/ExportDataUtils'
import { DeviceLogUploadClients, DeviceLogUploadTypes } from 'utils/Constants'
import { intlFormat } from 'date-fns'

const SyncHistoryReportColumns: ExportColumnType[] = [
  {
    key: 'uploadDate',
    label: 'Date',
    format: (val: any) =>
      val
        ? intlFormat(new Date(val), {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })
            .split(',')
            .join(' ')
        : 'Not available',
    cellWidth: 120,
  },
  {
    key: 'uploadLogType',
    label: 'Sync data',
    format: (val: string) =>
      val ? DeviceLogUploadTypes.find((log) => log.value === val)?.label ?? '' : 'Not available',
    cellWidth: 200,
  },
  {
    key: 'clientType',
    label: 'Synced via',
    format: (val: string) =>
      val
        ? DeviceLogUploadClients.find((client) => client.value === val)?.label ?? ''
        : 'Not available',
    cellWidth: 200,
  },
]

export { SyncHistoryReportColumns }

import React, { useEffect, useState, memo } from 'react'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import clsx from 'clsx'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'
import { sub } from 'date-fns'

import {
  DateRangeSelectorTypes,
  DeviceSyncFilterValues,
  SelectOptionTypes,
} from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'
import FilterFormTitle from 'components/formComponents/FilterFormTitle'

import {
  dateRangeOptions,
  selectAllAssignedUsersOption,
  selectAllOption,
  selectBlankOption,
  selectNoAssignedUsersOption,
  selectAllSyncedOption,
  selectNotSyncedOption,
  dateRangeProps,
} from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { max } from 'lodash'
import { DevicePageColumnIds, DevicePageFilterFormType } from './DevicesPageUtils'
import { iDeviceListDataWithStats } from '../data/DevicePageData'
import {
  DeviceStatusConstants,
  DeviceStatusEnums,
  PrepareDeviceTypeOptionsForSelect,
} from '../data/DevicesDataUtils'

interface DevicePageFilterFormProps {
  selectedFilterValues?: DevicePageFilterFormType
  optionsData: iDeviceListDataWithStats[]
  onFilterSubmit: (filterValues: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

// serialNumber: IselectOptionProps
// assetNumber: IselectOptionProps

// deviceType: IselectOptionProps
// businessUnit: IselectOptionProps
// deviceStatus: IselectOptionProps

// deviceUser: IselectOptionProps

// lastSync: DateRange
// lastSwitchOn: DateRange

// lastSyncSelector: IselectOptionProps
// lastSwitchOnSelector: IselectOptionProps

const defaultFilterValues = () => {
  const dtTo = new Date()
  const dtFrom = sub(new Date(), { months: 6 })
  const defValues: DevicePageFilterFormType = {
    serialNumber: selectBlankOption,
    assetNumber: selectBlankOption,
    deviceType: selectAllOption,
    deviceStatus: selectAllOption,
    deviceUser: selectBlankOption,
    businessUnit: selectAllOption,
    gasType: selectAllOption,
    lastSyncSelector: dateRangeOptions.find(
      (option) => option.label === DateRangeSelectorTypes.All,
    ) ?? {
      label: '',
      value: '',
    },
    lastSync: {
      startDate: dtFrom,
      endDate: dtTo,
    },
    lastSwitchOnSelector: dateRangeOptions.find(
      (option) => option.label === DateRangeSelectorTypes.All,
    ) ?? {
      label: '',
      value: '',
    },
    lastSwitchOn: {
      startDate: dtFrom,
      endDate: dtTo,
    },
  }
  return defValues
}

function DevicePageFilterForm(props: DevicePageFilterFormProps): JSX.Element {
  const { selectedFilterValues, optionsData, onFilterSubmit, onFilterReset, onFilterFormClose } =
    props
  // console.log('selectedFilterValues', selectedFilterValues.serialNumber)
  // console.log('defaultFilterValues', defaultFilterValues.serialNumber)
  const defaultFormValues = defaultFilterValues()

  const {
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<DevicePageFilterFormType>({
    defaultValues: defaultFormValues,
    values: selectedFilterValues,
    mode: 'all',
  })

  const watchLastSyncSelector = watch('lastSyncSelector')
  const watchLastSwitchOnSelector = watch('lastSwitchOnSelector')
  const customLastSyncStartDate = watch('lastSync.startDate')
  const customLastSyncEndDate = watch('lastSync.endDate')
  const customSwitchedOnStartDate = watch('lastSwitchOn.startDate')
  const customSwitchedOnEndDate = watch('lastSwitchOn.startDate')

  const [showCustomSyncDate, setShowCustomSyncDate] = useState(false)
  const [lastSyncDateRangeOptions, setLastSyncDateRangeOptions] =
    useState<IselectOptionProps[]>(dateRangeOptions)
  const [showCustomSwitchOnDate, setShowCustomSwitchOnDate] = React.useState(false)

  const [serialNumberOptions, setSerialNumberOptions] = useState<IselectOptionProps[]>([])
  const [assetNumberOptions, setAssetNumberOptions] = useState<IselectOptionProps[]>([])
  const [deviceTypeOptions, setDeviceTypeOptions] = useState<IselectOptionProps[]>([])
  const [businessUnitOptions, setBusinessUnitOptions] = useState<IselectOptionProps[]>([])
  const [deviceStatusOptions, setDeviceStatusOptions] = useState<IselectOptionProps[]>([])
  const [deviceUsersOptions, setDeviceUsersOptions] = useState<IselectOptionProps[]>([])

  const lastSyncDateRangeOptionsUpdated = [
    selectNotSyncedOption,
    selectAllSyncedOption,
    ...lastSyncDateRangeOptions,
  ]

  useEffect(() => {
    if (optionsData) {
      const serialNumbersInData: IselectOptionProps[] = optionsData?.map((device) => ({
        value: device.serialNumber ?? '',
        label: device.serialNumber ?? '',
      }))
      // remove duplicate serial numbers

      const uniqueSerialNumbers = new Set(serialNumbersInData.map((item) => item.value))
      const serialNumbersInDataUnique = Array.from(uniqueSerialNumbers).map((item) => ({
        value: item,
        label: item?.toString(),
      }))

      const assetNumbersInData: IselectOptionProps[] = optionsData?.map((device) => ({
        value: device.customerAssetNumber ?? '',
        label: device.customerAssetNumber ?? '',
      }))
      // remove duplicate asset numbers

      const uniqueAssetNumbers = new Set(assetNumbersInData.map((item) => item.value))
      const assetNumbersInDataUnique = Array.from(uniqueAssetNumbers).map((item) => ({
        value: item,
        label: item?.toString(),
      }))

      const businessUnitsInData: IselectOptionProps[] | undefined = optionsData?.map((device) => ({
        value: device.businessUnit?.id ?? '',
        label: device.businessUnit?.name ?? '',
      }))
      // remove duplicates in business units

      const businessUnitsInDataUnique: IselectOptionProps[] = businessUnitsInData.filter(
        (v, i, a) => a.findIndex((t) => t.value === v.value) === i,
      )

      businessUnitsInDataUnique.unshift(selectAllOption)

      const deviceTypesInData: IselectOptionProps[] = PrepareDeviceTypeOptionsForSelect(optionsData)

      const deviceUsersInData: IselectOptionProps[] = []
      optionsData?.forEach((device) => {
        if (device.deviceUser && device.deviceUser.fullName) {
          deviceUsersInData.push({
            value: device.deviceUser?.id ?? '',
            label: device.deviceUser?.fullName ?? '',
          })
        }
      })

      const deviceUsersInDataUnique: IselectOptionProps[] = deviceUsersInData?.filter(
        (v, i, a) => a.findIndex((t) => t?.value === v?.value) === i,
      )

      deviceUsersInDataUnique.unshift(selectAllAssignedUsersOption)
      deviceUsersInDataUnique.unshift(selectNoAssignedUsersOption)

      const deviceStatusInData: IselectOptionProps[] = optionsData?.map((device) => ({
        value: device.status ?? '',
        label: device.status ?? '',
      }))

      const uniqueDeviceStatus = new Set(deviceStatusInData.map((item) => item.value))
      const deviceStatusInDataUnique = Array.from(uniqueDeviceStatus).map((item) => ({
        value: item,
        label: DeviceStatusConstants[item as DeviceStatusEnums],
      }))

      setSerialNumberOptions(serialNumbersInDataUnique as IselectOptionProps[])
      setAssetNumberOptions(assetNumbersInDataUnique as IselectOptionProps[])
      setBusinessUnitOptions(businessUnitsInDataUnique as IselectOptionProps[])
      setDeviceTypeOptions(deviceTypesInData)
      setDeviceUsersOptions(deviceUsersInDataUnique as IselectOptionProps[])
      setDeviceStatusOptions(deviceStatusInDataUnique as IselectOptionProps[])
    }
  }, [optionsData])

  useEffect(() => {
    if (watchLastSyncSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomSyncDate(true)
    } else {
      setShowCustomSyncDate(false)
    }

    if (watchLastSwitchOnSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomSwitchOnDate(true)
    } else {
      setShowCustomSwitchOnDate(false)
    }
  }, [watchLastSyncSelector, watchLastSwitchOnSelector])

  const onSubmit: SubmitHandler<DevicePageFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: DevicePageColumnIds.serialNumber,
      value: data.serialNumber ? data.serialNumber.value : '',
    })

    filterValues.push({
      columnId: DevicePageColumnIds.assetNumber,
      value: data.assetNumber ? data.assetNumber.value : '',
    })

    filterValues.push({
      columnId: DevicePageColumnIds.deviceType,
      value: data.deviceType.value === SelectOptionTypes.All ? '' : data.deviceType.value,
    })

    filterValues.push({
      columnId: DevicePageColumnIds.businessUnit,
      value: data.businessUnit
        ? data.businessUnit.value === SelectOptionTypes.All
          ? ''
          : (data.businessUnit.label as string)
        : '',
    })

    filterValues.push({
      columnId: DevicePageColumnIds.deviceStatus,
      value: data.deviceStatus.value === SelectOptionTypes.All ? '' : data.deviceStatus.value,
    })

    filterValues.push({
      columnId: DevicePageColumnIds.deviceUser,
      value: data.deviceUser ?? selectBlankOption,
    })

    filterValues.push({
      columnId: DevicePageColumnIds.lastSync,
      value:
        data.lastSyncSelector.label === DateRangeSelectorTypes.All
          ? ''
          : data.lastSyncSelector.label === DeviceSyncFilterValues.AllSynced ||
            data.lastSyncSelector.label === DeviceSyncFilterValues.NotSynced
          ? {
              startDate: '',
              endDate: '',
              isCustom: true,
              customValue: data.lastSyncSelector.value,
            }
          : {
              label: data.lastSyncSelector.label as string,
              value: data.lastSync,
            },
    })
    // lastSyncSelector

    filterValues.push({
      columnId: DevicePageColumnIds.lastSwitchOn,
      value:
        data.lastSwitchOnSelector.label === DateRangeSelectorTypes.All
          ? ''
          : {
              label: data.lastSwitchOnSelector.label as string,
              value: data.lastSwitchOn,
            },
    })

    onFilterSubmit(filterValues)
  }

  const onError = (formErrors: FieldErrors<DevicePageFilterFormType>) => {
    console.log(formErrors)
  }

  const onReset = () => {
    reset(defaultFilterValues)
    onFilterReset()
  }

  return (
    <div className={clsx('filter-form-container')}>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button'
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit, onError)} onReset={onReset} noValidate className=''>
        <div className='filter-form-fields-container'>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultsrFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Serial Number
            </label>
            <Controller
              name='serialNumber'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: serialNumberOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    // maxMenuHeight: 130,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.serialNumber?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='devicesassetnumfilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Asset Number
            </label>
            <Controller
              name='assetNumber'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: assetNumberOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    // maxMenuHeight: 130,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.assetNumber?.message}</p>
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch w-full'>
            <label
              htmlFor='deviceTypeFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Device Type
            </label>

            <Controller
              name='deviceType'
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: deviceTypeOptions,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select device type',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='businessUnitFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Business Unit
            </label>
            <Controller
              name='businessUnit'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: businessUnitOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select business unit',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.businessUnit?.message}</p>
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='businessUnitFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Device Status
            </label>
            <Controller
              name='deviceStatus'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: deviceStatusOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select Status',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceStatus?.message}</p>
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='devicesassetnumfilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Assigned To
            </label>
            <Controller
              name='deviceUser'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: deviceUsersOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    // maxMenuHeight: 130,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceUser?.message}</p>
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultDateRangeSelector'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Last Sync
            </label>

            <Controller
              name='lastSyncSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: lastSyncDateRangeOptionsUpdated,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: false,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Last Sync',
                    isClearable: false,
                    // value: field.value,
                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue === DeviceSyncFilterValues.AllSynced) {
                        const allSyncedVal: dateRangeProps = {
                          startDate: '',
                          endDate: '',
                          isCustom: true,
                          customValue: DeviceSyncFilterValues.AllSynced,
                        }
                        setValue('lastSync', allSyncedVal, {
                          shouldValidate: true,
                        })
                      } else if (selectedValue === DeviceSyncFilterValues.NotSynced) {
                        const noSyncedVal: dateRangeProps = {
                          startDate: '',
                          endDate: '',
                          isCustom: true,
                          customValue: DeviceSyncFilterValues.NotSynced,
                        }
                        setValue('lastSync', noSyncedVal, {
                          shouldValidate: true,
                        })
                      } else if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('lastSync', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('lastSync', {
                          startDate: selectedFilterValues?.lastSync.startDate || new Date(),
                          endDate: selectedFilterValues?.lastSync.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>
          {showCustomSyncDate && (
            <div className='flex flex-row justify-between  items-end '>
              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start '>
                  <div>
                    <label
                      htmlFor='faultDateFrom'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      From
                    </label>
                  </div>
                  <div className=''>
                    <Controller
                      name='lastSync.startDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,
                            maxDate: customLastSyncEndDate,
                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className='mx-0.5 w-4 h-4 my-3'>
                <ChevronRightIcon className='' />
              </div>

              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start'>
                  <label
                    htmlFor='faultDateFilterTo'
                    className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                  >
                    To
                  </label>
                  <div className=''>
                    <Controller
                      name='lastSync.endDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,
                            minDate: customLastSyncStartDate,
                            maxDate: new Date(),
                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {errors.lastSync && (
            <>
              <p className='text-xs text-red-500'>{errors.lastSync?.startDate?.message}</p>
              <p className='text-xs text-red-500'>{errors.lastSync?.endDate?.message}</p>
            </>
          )}
          {/* Last SW ON */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultDateRangeSelector'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Last switched on
            </label>

            <Controller
              name='lastSwitchOnSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: false,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Last switch on',
                    isClearable: false,
                    // value: field.value,
                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('lastSwitchOn', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('lastSwitchOn', {
                          startDate: selectedFilterValues?.lastSwitchOn.startDate || new Date(),
                          endDate: selectedFilterValues?.lastSwitchOn.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>
          {showCustomSwitchOnDate && (
            <div className='flex flex-row justify-between  items-end '>
              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start '>
                  <div>
                    <label
                      htmlFor='faultDateFrom'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      From
                    </label>
                  </div>
                  <div className=''>
                    <Controller
                      name='lastSwitchOn.startDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,
                            maxDate: customSwitchedOnEndDate,
                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className='mx-0.5 w-4 h-4 my-3'>
                <ChevronRightIcon className='' />
              </div>

              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start'>
                  <label
                    htmlFor='faultDateFilterTo'
                    className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                  >
                    To
                  </label>
                  <div className=''>
                    <Controller
                      name='lastSwitchOn.endDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,
                            minDate: customSwitchedOnStartDate,
                            maxDate: new Date(),
                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {errors.lastSwitchOn && (
            <>
              <p className='text-xs text-red-500'>{errors.lastSwitchOn?.startDate?.message}</p>
              <p className='text-xs text-red-500'>{errors.lastSwitchOn?.endDate?.message}</p>
            </>
          )}
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button disabled:filter-form-reset-button disabled:cursor-not-allowed'
            type='submit'
            value='Apply'
            disabled={!isDirty || isSubmitting || !isValid}
          />
        </div>
      </form>

      {/* <DevTool control={control} /> */}
    </div> // opening div
  )
}

export default withErrorBoundary(memo(DevicePageFilterForm), {
  FallbackComponent: ErrorPage,
})

// implement a context
// state and dispatch
// state shows the following:
// filterform shown true or false
// page being shown
// filterform data

// dispatch actions has the following:
// show filter form
// hide filter form

import React, { createContext, useReducer, useMemo } from 'react'

export enum DeviceDetailContextActions {
  ShowFilterForm = 'SHOW_FILTER_FORM',
  HideFilterForm = 'HIDE_FILTER_FORM',
  UpdateFilterFormData = 'UPDATE_FILTER_FORM_DATA',
}

// Define the initial state
interface iDeviceDetailsState {
  filterFormShown: boolean
  page: string
  filterFormData: any
}

interface iDeviceDetailsContext {
  state: iDeviceDetailsState
  dispatch: React.Dispatch<any>
}

const initialState: iDeviceDetailsState = {
  filterFormShown: false,
  page: '',
  filterFormData: null,
}

// Define the reducer function
function reducer(state: iDeviceDetailsState, action: { type: string; payload: any }) {
  switch (action.type) {
    case DeviceDetailContextActions.ShowFilterForm:
      return {
        ...state,
        filterFormShown: true,
        filterFormData: action.payload.filterFormData,
        page: action.payload.page,
      }
    case DeviceDetailContextActions.HideFilterForm:
      return { ...state, filterFormShown: false }
    case DeviceDetailContextActions.UpdateFilterFormData:
      return { ...state, filterFormData: action.payload.filterFormData }
    default:
      return state
  }
}

// Create the context
export const DeviceDetailsContext = createContext({} as iDeviceDetailsContext)

// Create the context provider component

export function DeviceDetailsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  // const [state, dispatch] = useReducer(userAccountReducer, initialUserAccountState)

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])
  // const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return (
    <DeviceDetailsContext.Provider value={contextValue}>{children}</DeviceDetailsContext.Provider>
  )
}

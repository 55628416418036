import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { isAfter, isBefore, isEqual, sub } from 'date-fns'
import { useDeviceDetails } from 'pages/deviceDetails/rootPage/view/DeviceDetailsRoot'
import { ColumnDef, Table } from '@tanstack/react-table'
import { FormattedDate, FormattedTime } from 'react-intl'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { DateRangeColumnFilterFn, dateRangeOptions, selectAllOption } from 'forms/FormUtils'
import TsTable from 'components/table/TsTable'
import { DateRangeSelectorTypes, DeviceDetailPageTabs, UserStatusTypes } from 'utils/CommonEnums'
import { ScreenWidths, returnDateWithoutTimeOffset } from 'utils/Constants'
import {
  DeviceDetailContextActions,
  DeviceDetailsContext,
} from 'pages/deviceDetails/rootPage/view/DeviceDetailsContext'
import ExportData from 'exportReports/ExportData'
import Tooltip from 'components/atom/Tooltip'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import DeviceDetailsReportSelector from 'pages/deviceDetails/rootPage/view/DeviceDetailsReportSelector'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { useDeviceAssignmentHistoryData } from '../data/DeviceAssignmentHistoryData'
import { NavigateBackButton } from '../../../rootPage/view/NavigateBackButton'
import { iAssignmentHistory } from '../data/interface'
import { AssignmentHistoryReportColumns } from '../export/AssignmentHistoryReportFormat'
import AssignmentHistoryFilterFormat from '../export/AssignmentHistoryFilterFormat'

export const columnIDs = {
  deviceUserName: 'fullName',
  deviceUserUniqueID: 'uniqueId',
  deviceUserAssignedOn: 'assignedOn',
  deviceUserReturnedOn: 'unAssignedOn',
}

export type DeviceAssignmentHistoryFilterFormType = {
  assignedDateSelector: IselectOptionProps
  assignedDate: DateRange
  returnedDateSelector: IselectOptionProps
  returnedDate: DateRange
  deviceUserName: IselectOptionProps
  deviceUserUniqueID: IselectOptionProps
}

export const DeviceEventLogFilterParamLabels = {
  from: 'from',
  to: 'to',
  assignedDateRangeFrom: 'assignedfrom',
  assignedDateRangeTo: 'assignedto',
  returnDateFrom: 'returnDateFrom',
  returnDateTo: 'returnDateTo',
  deviceUser: 'user',
  deviceUserUniqueID: 'uniqueid',
  status: 'status',
}
// fullName: string
//   uniqueId: string
//   assignedOn: Date | string | null
//   unAssignedOn: Date | string | null
//   status: UserStatusTypes

function DeviceAssignmentHistory() {
  // Implement the component logic here
  const [is2xlAndAbove, setIs2xlAndAbove] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIs2xlAndAbove(window.innerWidth >= ScreenWidths.xxl)
    }

    handleResize() // Initial check

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const deviceDetailsContext = useContext(DeviceDetailsContext)
  const { dispatch } = deviceDetailsContext
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`
  const tableRef = useRef<Table<iAssignmentHistory>>(null)
  const sortedTableData =
    tableRef.current?.getSortedRowModel().rows.map((row) => row.original) ?? []

  const [searchParams, setSearchParams] = useSearchParams()
  const [showExportDialog, setShowExportDialog] = React.useState(false)

  /**
   * Get the search params from the URL
   * @param {string} from - The date from which the data should be fetched
   * @param {string} to - The date to which the data should be fetched
   * @param {string} assignedDateRangeFrom - The date from which the data should be fetched
   * @param {string} assignedDateRangeTo - The date to which the data should be fetched
   */
  const isToDate =
    searchParams.get(DeviceEventLogFilterParamLabels.to) ||
    searchParams.get(DeviceEventLogFilterParamLabels.assignedDateRangeTo)

  const isFromDate =
    searchParams.get(DeviceEventLogFilterParamLabels.from) ||
    searchParams.get(DeviceEventLogFilterParamLabels.assignedDateRangeFrom)

  const assignedDateRangeTo = returnDateWithoutTimeOffset(isToDate || new Date())
  const assignedDateRangeFrom = returnDateWithoutTimeOffset(
    isFromDate || sub(new Date(assignedDateRangeTo), { months: 6 }),
  )

  /**
   * Get the search params from the URL
   * @param {string} returnDateRangeFrom - The date from which the data should be fetched
   * @param {string} returnDateRangeTo - The date to which the data should be fetched
   */

  const isReturnDateTo = searchParams.get(DeviceEventLogFilterParamLabels.returnDateTo) || ''
  const isReturnDateFrom = searchParams.get(DeviceEventLogFilterParamLabels.returnDateFrom) || ''

  const returnDateRangeTo = returnDateWithoutTimeOffset(isReturnDateTo || new Date())
  const returnDateRangeFrom = returnDateWithoutTimeOffset(
    isReturnDateFrom || sub(new Date(returnDateRangeTo), { months: 6 }),
  )

  const userNameParam = searchParams.get(DeviceEventLogFilterParamLabels.deviceUser) ?? ''
  const uniqueIDParam = searchParams.get(DeviceEventLogFilterParamLabels.deviceUserUniqueID) ?? ''
  const statusParam = searchParams.get(DeviceEventLogFilterParamLabels.status) ?? ''

  const params = useParams()
  const { deviceSerialNumber } = params as { deviceSerialNumber: string }

  const { deviceDetails } = useDeviceDetails()
  const { id: deviceId } = deviceDetails ?? { deviceId: '' }

  document.title = `Assignment History ${deviceSerialNumber}`

  const [filterFormDeviceUserNames, setFilterFormDeviceUserNames] = useState<IselectOptionProps[]>(
    [],
  )

  const [filterFormDeviceUserUniqueIDs, setFilterFormDeviceUserUniqueIDs] = useState<
    IselectOptionProps[]
  >([])

  const [filterFormDeviceUserStatuses, setFilterFormDeviceUserStatuses] = useState<
    IselectOptionProps[]
  >([])

  const { genericEventHandler } = useGenericEventHandler()
  const {
    data: deviceAssignmentHistory,
    isLoading: isDeviceAssignmentHistoryLoading,
    isError: isDeviceAssignmentHistoryError,
    error: deviceAssignmentHistoryError,
  } = useDeviceAssignmentHistoryData(
    deviceId as string,
    redirectPageURL,
    assignedDateRangeFrom.toISOString().split('T')[0],
    assignedDateRangeTo.toISOString().split('T')[0],
    true,
  )

  useEffect(() => {
    if (isDeviceAssignmentHistoryError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: deviceAssignmentHistoryError?.message || 'Error getting device assignment history',
        error: deviceAssignmentHistoryError,
        extraData: {
          component: 'DeviceAssignmentHistory',
          action: 'get device assignment history',
        },
      })
    }
  }, [isDeviceAssignmentHistoryError, deviceAssignmentHistoryError])

  useEffect(() => {
    if (deviceAssignmentHistory && deviceAssignmentHistory.length > 0) {
      const deviceUserNames: IselectOptionProps[] = []
      const deviceUserUniqueIDs: IselectOptionProps[] = []

      deviceAssignmentHistory.forEach((history) => {
        if (!deviceUserNames.find((user) => user.value === history.fullName)) {
          deviceUserNames.push({
            label: history.fullName,
            value: history.fullName,
            isDisabled: false,
          })
        }
        if (!deviceUserUniqueIDs.find((user) => user.value === history.uniqueId)) {
          deviceUserUniqueIDs.push({
            label: history.uniqueId,
            value: history.uniqueId,
            isDisabled: false,
          })
        }
      })

      deviceUserNames.unshift(selectAllOption)
      deviceUserUniqueIDs.unshift(selectAllOption)

      const deviceUserStatusesOptions: IselectOptionProps[] = []
      Object.keys(UserStatusTypes).forEach((key) => {
        deviceUserStatusesOptions.push({
          label: key,
          value: key,
          isDisabled: false,
        })
      })

      deviceUserStatusesOptions.unshift(selectAllOption)

      setFilterFormDeviceUserNames(deviceUserNames)
      setFilterFormDeviceUserUniqueIDs(deviceUserUniqueIDs)
      setFilterFormDeviceUserStatuses(deviceUserStatusesOptions)
    }
  }, [deviceAssignmentHistory])

  const deviceAssignmentHistoryColumns = useMemo<ColumnDef<iAssignmentHistory>[]>(
    () => [
      {
        id: columnIDs.deviceUserName,
        header: 'Name',
        accessorKey: 'fullName',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <Tooltip
              id={`device-assignment-history-name-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={value}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4 font-semibold text-c-dark-blue-1 text-ellipsis '
            >
              {value}
            </Tooltip>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: columnIDs.deviceUserUniqueID,
        header: 'Unique ID',
        accessorKey: 'uniqueId',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1  '>
              {value}
            </div>
          )
        },

        meta: {},
        filterFn: 'includesString',
      },
      {
        id: columnIDs.deviceUserAssignedOn,
        header: 'Assigned on',
        accessorKey: 'assignedOn',
        cell: (info) => {
          const value: string = info.getValue() as string

          if (value) {
            const date = new Date(value)
            return (
              <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
                <span className='mr-2'>
                  <FormattedDate value={date} />
                </span>
                <span>
                  <FormattedTime value={date} />
                </span>
              </div>
            )
          }
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
              <span className='mr-2'>Not available</span>
            </div>
          )
        },

        meta: {},
        filterFn: DateRangeColumnFilterFn<iAssignmentHistory>,
      },
      {
        id: columnIDs.deviceUserReturnedOn,
        header: 'Returned on',
        accessorKey: 'unAssignedOn',
        cell: (info) => {
          const value: string = info.getValue() as string
          if (value) {
            const date = new Date(value)
            return (
              <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1  '>
                <span className='mr-2'>
                  <FormattedDate value={date} />
                </span>
                <span>
                  <FormattedTime value={date} />
                </span>
              </div>
            )
          }
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
              <span className='mr-2'>Not available</span>
            </div>
          )
        },

        meta: {},
        filterFn: () => true,
      },
    ],
    [],
  )

  const resetFilterFormParams = () => {
    searchParams.delete(DeviceEventLogFilterParamLabels.assignedDateRangeFrom)
    searchParams.delete(DeviceEventLogFilterParamLabels.assignedDateRangeTo)
    searchParams.delete(DeviceEventLogFilterParamLabels.returnDateFrom)
    searchParams.delete(DeviceEventLogFilterParamLabels.returnDateTo)
    searchParams.delete(DeviceEventLogFilterParamLabels.from)
    searchParams.delete(DeviceEventLogFilterParamLabels.to)
    searchParams.delete(DeviceEventLogFilterParamLabels.deviceUser)
    searchParams.delete(DeviceEventLogFilterParamLabels.deviceUserUniqueID)
    searchParams.delete(DeviceEventLogFilterParamLabels.status)
    setSearchParams(searchParams)
  }

  let assignedDateRangeIsFiltered = false
  let assignedDateRangeIsWider = false
  let assignedDateRangeToBeFiltered = {} as DateRange
  let returnDateRangeIsFiltered = false
  let returnDateRangeIsWider = false
  let returnDateRangeToBeFiltered = {} as DateRange

  const filterTableValues = useMemo(
    () => [
      {
        columnId: columnIDs.deviceUserAssignedOn,
        value:
          isToDate && isFromDate
            ? {
                startDate: new Date(assignedDateRangeFrom),
                endDate: new Date(assignedDateRangeTo),
              }
            : '',
      },
      {
        columnId: columnIDs.deviceUserUniqueID,
        value: uniqueIDParam ?? '',
      },
      {
        columnId: columnIDs.deviceUserName,
        value: userNameParam ?? '',
      },
      {
        columnId: columnIDs.deviceUserReturnedOn,
        value:
          isReturnDateFrom && isReturnDateTo
            ? {
                startDate: new Date(returnDateRangeFrom),
                endDate: new Date(returnDateRangeTo),
              }
            : '',
      },
    ],
    [
      assignedDateRangeFrom,
      assignedDateRangeTo,
      returnDateRangeFrom,
      returnDateRangeTo,
      isToDate,
      isFromDate,
      isReturnDateFrom,
      isReturnDateTo,
      userNameParam,
      uniqueIDParam,
    ],
  )

  const convertISelectOptionPropsToFormType = useMemo(() => {
    const assignedDateRangeFiltered = (filterTableValues.find(
      (filter) => filter.columnId === columnIDs.deviceUserAssignedOn,
    )?.value || {
      startDate: assignedDateRangeFrom,
      endDate: assignedDateRangeTo,
    }) as DateRange

    const returnedDateRangeFiltered = (filterTableValues.find(
      (filter) => filter.columnId === columnIDs.deviceUserReturnedOn,
    )?.value || {
      startDate: returnDateRangeFrom,
      endDate: returnDateRangeTo,
    }) as DateRange

    const deviceUserFiltered =
      filterTableValues.find((filter) => filter.columnId === columnIDs.deviceUserName)?.value ?? ''

    const deviceUserUniqueIDFiltered =
      filterTableValues.find((filter) => filter.columnId === columnIDs.deviceUserUniqueID)?.value ??
      ''

    const r: DeviceAssignmentHistoryFilterFormType = {
      assignedDateSelector: dateRangeOptions.find(
        (option) => option.value === DateRangeSelectorTypes.Custom,
      ) ?? {
        label: '',
        value: '',
      },

      assignedDate: {
        startDate: new Date(assignedDateRangeFiltered.startDate),
        endDate: new Date(assignedDateRangeFiltered.endDate),
      },

      returnedDateSelector: dateRangeOptions.find(
        (option) => option.value === DateRangeSelectorTypes.Custom,
      ) ?? {
        label: '',
        value: '',
      },

      returnedDate: {
        startDate: new Date(returnedDateRangeFiltered.startDate),
        endDate: new Date(returnedDateRangeFiltered.endDate),
      },

      deviceUserName: {
        value: deviceUserFiltered,
        label: deviceUserFiltered as string,
      },

      deviceUserUniqueID: {
        value: deviceUserUniqueIDFiltered,
        label: deviceUserUniqueIDFiltered as string,
      },
    }
    return r
  }, [
    filterTableValues,
    assignedDateRangeFrom,
    assignedDateRangeTo,
    returnDateRangeFrom,
    returnDateRangeTo,
    isToDate,
    isFromDate,
    userNameParam,
    uniqueIDParam,
    statusParam,
  ])

  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      formData.forEach((filter) => {
        if (filter.columnId === columnIDs.deviceUserUniqueID && filter?.value !== null) {
          if (filter.value === '') {
            searchParams.delete(DeviceEventLogFilterParamLabels.deviceUserUniqueID)
          } else {
            searchParams.set(
              DeviceEventLogFilterParamLabels.deviceUserUniqueID,
              filter.value as string,
            )
          }
        }

        if (filter.columnId === columnIDs.deviceUserName) {
          if (filter.value === '') {
            searchParams.delete(DeviceEventLogFilterParamLabels.deviceUser)
          } else {
            searchParams.set(DeviceEventLogFilterParamLabels.deviceUser, filter.value as string)
          }
        }

        if (filter.columnId === columnIDs.deviceUserAssignedOn) {
          if (filter.value === '') {
            searchParams.delete(DeviceEventLogFilterParamLabels.assignedDateRangeFrom)
            searchParams.delete(DeviceEventLogFilterParamLabels.assignedDateRangeTo)
          } else {
            assignedDateRangeToBeFiltered = filter?.value as DateRange

            // Calculate if DateRange is wider based on below code
            const isFromBeforeOrSame =
              isBefore(
                new Date(assignedDateRangeToBeFiltered.startDate),
                new Date(assignedDateRangeFrom),
              ) ||
              isEqual(
                new Date(assignedDateRangeToBeFiltered.startDate),
                new Date(assignedDateRangeFrom),
              )

            const isToAfterOrSame =
              isAfter(
                new Date(assignedDateRangeToBeFiltered.endDate),
                new Date(assignedDateRangeTo),
              ) ||
              isEqual(
                new Date(assignedDateRangeToBeFiltered.endDate),
                new Date(assignedDateRangeTo),
              )
            assignedDateRangeIsFiltered = true
            assignedDateRangeIsWider = isFromBeforeOrSame && isToAfterOrSame
          }
        }

        if (filter.columnId === columnIDs.deviceUserReturnedOn) {
          if (filter.value === '') {
            searchParams.delete(DeviceEventLogFilterParamLabels.returnDateFrom)
            searchParams.delete(DeviceEventLogFilterParamLabels.returnDateTo)
          } else {
            // const dateRangeToBeFiltered = filter?.value as DateRange
            returnDateRangeToBeFiltered = filter?.value as DateRange
            // Calculate if DateRange is wider based on below code
            const isFromBeforeOrSame =
              isBefore(
                new Date(returnDateRangeToBeFiltered.startDate),
                new Date(returnDateRangeFrom),
              ) ||
              isEqual(
                new Date(returnDateRangeToBeFiltered.startDate),
                new Date(returnDateRangeFrom),
              )

            const isToAfterOrSame =
              isAfter(new Date(returnDateRangeToBeFiltered.endDate), new Date(returnDateRangeTo)) ||
              isEqual(new Date(returnDateRangeToBeFiltered.endDate), new Date(returnDateRangeTo))

            returnDateRangeIsFiltered = true
            returnDateRangeIsWider = isFromBeforeOrSame && isToAfterOrSame
          }
        }

        // searchParams.set(
        //   DeviceEventLogFilterParamLabels.filterDateFrom,
        //   new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
        // )

        // searchParams.set(
        //   DeviceEventLogFilterParamLabels.filterDateTo,
        //   new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
        // )

        // if (isDateRangeWider) {
        //   searchParams.set(
        //     DeviceEventLogFilterParamLabels.dateRangeFrom,
        //     new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
        //   )

        //   searchParams.set(
        //     DeviceEventLogFilterParamLabels.dateRangeTo,
        //     new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
        //   )
        // }
      })

      if (assignedDateRangeIsWider || returnDateRangeIsWider) {
        const lowerFromDate =
          assignedDateRangeToBeFiltered.startDate < returnDateRangeToBeFiltered.startDate
            ? assignedDateRangeToBeFiltered.startDate
            : returnDateRangeToBeFiltered.startDate
        const lowerToDate =
          assignedDateRangeToBeFiltered.endDate < returnDateRangeToBeFiltered.endDate
            ? assignedDateRangeToBeFiltered.endDate
            : returnDateRangeToBeFiltered.endDate

        searchParams.set(
          DeviceEventLogFilterParamLabels.from,
          new Date(lowerFromDate).toISOString().split('T')[0],
        )
        searchParams.set(
          DeviceEventLogFilterParamLabels.to,
          new Date(lowerToDate).toISOString().split('T')[0],
        )
      }

      if (assignedDateRangeIsFiltered) {
        searchParams.set(
          DeviceEventLogFilterParamLabels.assignedDateRangeFrom,
          new Date(assignedDateRangeToBeFiltered.startDate).toISOString().split('T')[0],
        )

        searchParams.set(
          DeviceEventLogFilterParamLabels.assignedDateRangeTo,
          new Date(assignedDateRangeToBeFiltered.endDate).toISOString().split('T')[0],
        )
      }

      if (returnDateRangeIsFiltered) {
        searchParams.set(
          DeviceEventLogFilterParamLabels.returnDateFrom,
          new Date(returnDateRangeToBeFiltered.startDate).toISOString().split('T')[0],
        )

        searchParams.set(
          DeviceEventLogFilterParamLabels.returnDateTo,
          new Date(returnDateRangeToBeFiltered.endDate).toISOString().split('T')[0],
        )
      }

      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const showFilter = () => {
    dispatch({
      type: DeviceDetailContextActions.ShowFilterForm,
      payload: {
        page: DeviceDetailPageTabs.AssignmentHistory,
        filterFormData: {
          onFilterSubmit: (formData: IFilterValues[]) => handleFilterFormSubmit(formData),
          onFilterReset: () => resetFilterFormParams(),
          // onFilterFormClose: () => setShowFilterForm(false),
          selectedValues: convertISelectOptionPropsToFormType,
          deviceUserNames: filterFormDeviceUserNames,
          deviceUserUniqueIds: filterFormDeviceUserUniqueIDs,
        },
      },
    })
  }

  const hideFilterForm = () => {
    dispatch({ type: DeviceDetailContextActions.HideFilterForm })
  }

  // useEffect(() => {
  //   if (is2xlAndAbove) {
  //     showFilter()
  //   } else {
  //     hideFilterForm()
  //   }
  // }, [is2xlAndAbove])

  // Return the JSX that will be rendered
  return (
    <>
      {/* <div className={clsx('flex flex-row ', state.filterFormShown && '')}> */}
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog}
        data={sortedTableData ?? []}
        reportColumns={AssignmentHistoryReportColumns}
        widgetForReport={[]}
        reportTitle='Device assignment history'
        filterSummary={AssignmentHistoryFilterFormat(
          filterTableValues,
          deviceDetails?.serialNumber || '',
          deviceDetails?.deviceType || '',
        )}
      />
      {/* <div className='flex-grow overflow-x-auto'> */}
      <TsTable
        ref={tableRef}
        data={deviceAssignmentHistory ?? []}
        dataIsLoading={isDeviceAssignmentHistoryLoading}
        columns={deviceAssignmentHistoryColumns}
        showGlobalActionButton={false}
        globalActionButton={<NavigateBackButton />}
        showGlobalFilter={false}
        getRowCanExpand={() => false}
        onPrint={() => {}}
        onExport={() => {}}
        updateFilteredData={() => {}}
        renderFilterSummary={() => <> </>}
        filterValues={filterTableValues}
        onShowFilter={() => showFilter()}
        resetFilter={() => resetFilterFormParams()}
        minDisplayRows={10}
        defaultSortedColumKey={columnIDs.deviceUserAssignedOn}
        renderTabs={() => <DeviceDetailsReportSelector />}
        setShowExportDialog={(val) => setShowExportDialog(val)} // disabling export dialog action
      />
      {/* </div> */}
      {/* </div> */}
    </>
  )
}

export default withErrorBoundary(withPageTracking(DeviceAssignmentHistory), {
  FallbackComponent: ErrorPage,
})

// {/* <DeviceAssignmentHistoryFilterForm
//   onFilterSubmit={filterAssignmentHistory}
//   onFilterReset={() => {}}
//   onFilterFormClose={() => {}}
//   selectedValues={filterFormSelectedValues}
//   deviceUserNames={filterFormDeviceUserNames}
//   deviceUserUniqueIds={filterFormDeviceUserUniqueIDs}
// /> */}

// const filterAssignmentHistory = (formFilterValues: IFilterValues[]) => {
//   const filterValueAssignmentDateRange = formFilterValues.find(
//     (filterValue) => filterValue.columnId === columnIDs.deviceUserAssignedOn,
//   )?.value as DateRange

//   const isDateRangeNarrower =
//     (isAfter(
//       new Date(filterValueAssignmentDateRange.startDate),
//       new Date(assignmentHistoryFromToDates.startDate),
//     ) ||
//       isEqual(
//         new Date(filterValueAssignmentDateRange.startDate),
//         new Date(assignmentHistoryFromToDates.startDate),
//       )) &&
//     (isBefore(
//       new Date(assignmentHistoryFromToDates.endDate),
//       new Date(filterValueAssignmentDateRange.endDate),
//     ) ||
//       isEqual(
//         new Date(assignmentHistoryFromToDates.endDate),
//         new Date(filterValueAssignmentDateRange.endDate),
//       ))

//   if (isDateRangeNarrower) {
//     setFilterValues(formFilterValues)
//   } else {
//     const { startDate, endDate } = filterValueAssignmentDateRange

//     // navigate(`/devices/${deviceSerialNumber}/eventlog?from=${startDate}&to=${endDate}`)

//     setSearchParams({
//       from: new Date(startDate).toISOString().split('T')[0],
//       to: new Date(endDate).toISOString().split('T')[0],
//     })
//   }
// }

// const [filterFormSelectedValues, setFilterFormSelectedValues] =
//     useState<DeviceAssignmentHistoryFilterFormType>({
//       assignedDateSelector: dateRangeOptions.find(
//         (option) => option.value === DateRangeSelectorTypes.Custom,
//       ) ?? {
//         label: '',
//         value: '',
//       },

//       assignedDate: {
//         startDate: new Date(from),
//         endDate: new Date(to),
//       },

//       returnedDateSelector: dateRangeOptions.find(
//         (option) => option.value === DateRangeSelectorTypes.All,
//       ) ?? {
//         label: '',
//         value: '',
//       },

//       returnedDate: {
//         startDate: new Date(),
//         endDate: new Date(),
//       },

//       deviceUserName: {
//         label: '',
//         value: '',
//       },

//       deviceUserUniqueID: {
//         label: '',
//         value: '',
//       },
//     })

/*
TODO:
Refactoring required:
1. Column width for description - need to add max-width. Max-width needs to be calculated
based on available width and other columns width (also considering side nav status etc)



*/

import React, { useEffect, useMemo } from 'react'
import { ColumnDef, Table } from '@tanstack/react-table'
import { fromUnixTime, isValid } from 'date-fns'
import { FormattedDate, FormattedTime } from 'react-intl'

import { IFilterValues } from 'forms/FormModelInterface'
import { CustomerPropertyFilter, DateRangeColumnFilterUnixTimeFn } from 'forms/FormUtils'
import { AlarmTypeValues } from 'utils/Constants'

import TsTable from 'components/table/TsTable'
import Tooltip from 'components/atom/Tooltip'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { useLocation } from 'react-router-dom'
import ExportData from 'exportReports/ExportData'
import { SafetyPageColumnIds } from './SafetyPageUtils'
import { iSafetyData } from '../data/iSafetyList'
import { SafetyReportColumns } from '../export/SafetyReportFormat'

interface safetyPageTableProps {
  tableData?: iSafetyData[]
  isLoading: boolean
  filterValues?: IFilterValues[]
  updateFilteredData: (data: iSafetyData[]) => void
  onShowFilter: () => void
  onFilterFormSubmit: (filterValues: IFilterValues[]) => void
  onFilterFormReset: () => void

  pageSize: number
  pageIndex: number
  totalRecords: number
  setPageSize: (size: number) => void
  setPageIndex: (index: number) => void
  filterSummary?: string
}

function SafetyPageTable(tableDataProps: safetyPageTableProps): JSX.Element {
  const {
    tableData,
    filterValues,
    isLoading,
    updateFilteredData,
    onShowFilter,
    onFilterFormReset,
    pageSize = 10,
    pageIndex = 0,
    totalRecords = 0,
    setPageSize,
    setPageIndex,
    filterSummary,
  } = tableDataProps

  const [showExportDialog, setShowExportDialog] = React.useState(false)
  const tableRef = React.useRef<Table<iSafetyData>>(null)
  const sortedData = tableRef.current?.getSortedRowModel().rows.map((row) => row.original) ?? []

  const tableColumns = useMemo<ColumnDef<iSafetyData>[]>(
    () => [
      {
        id: SafetyPageColumnIds.serialNumber,
        header: 'Serial  #',
        accessorKey: 'SerialNumber',
        cell: (info) => {
          const value: string = info.getValue() as string
          return <div className='font-bold text-2xs leading-5  text-ellipsis '>{value}</div>
        },
        enableHiding: false,
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: SafetyPageColumnIds.alarmDate,
        header: 'Date & Time',
        accessorKey: 'TimeStamp',
        cell: (info) => {
          const value = info.getValue() as number
          const date = fromUnixTime(value)
          const isAlarmDateTimeValid = isValid(date)
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
              {isAlarmDateTimeValid && (
                <>
                  <span className='mr-2'>
                    <FormattedDate value={date} />
                  </span>
                  <span>
                    <FormattedTime value={date} />
                  </span>
                </>
              )}
            </div>
          )
        },
        meta: {},
        filterFn: DateRangeColumnFilterUnixTimeFn<iSafetyData>,
      },
      {
        id: SafetyPageColumnIds.assetNumber,
        header: 'Asset No.',
        accessorKey: 'AssetNumber',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div className='font-poppins text-2xs leading-4  text-ellipsis font-normal  text-c-dark-blue-1 '>
              {value}
            </div>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: SafetyPageColumnIds.businessUnit,
        header: 'Business Unit',
        accessorKey: 'businessUnitName',
        cell: (info) => {
          const businessUnitName = info.getValue() as string
          return businessUnitName ? (
            <Tooltip
              id={`safety-business-unit-${businessUnitName}`}
              showOnlyWhenTextIsTruncate
              tooltipText={businessUnitName}
              toolTipClass='tooltip'
              className={`  font-normal text-2xs leading-5  text-ellipsis `}
            >
              {businessUnitName}
            </Tooltip>
          ) : (
            <div className='w-40 mr-2 h-5 bg-c-light-blue-1 rounded-lg animate-pulse' />
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      // {
      //   id: SafetyPageColumnIds.deviceType,
      //   header: 'Device',
      //   accessorKey: 'deviceType',
      //   cell: (info) => {
      //     const value: string = info.getValue() as string
      //     return (
      //       <div className='pl-1 py-3 w-20 text-2xs font-normal leading-5  text-ellipsis'>{value}</div>
      //     )
      //   },
      //   meta: {
      //     colClassName: 'w-24',
      //   },
      //   filterFn: 'includesString',
      // },

      {
        id: SafetyPageColumnIds.gasName,
        header: 'Gas',
        accessorKey: 'Gas',
        cell: (info) => {
          const value = info.getValue() as string
          return (
            <div className='font-poppins text-2xs leading-4  text-ellipsis font-normal  text-c-dark-blue-1  '>
              {value}
            </div>
          )
        },
        meta: {},
        filterFn: 'equalsString',
      },
      {
        id: SafetyPageColumnIds.alarmType,
        header: 'Alarm',
        accessorKey: 'alarm',
        cell: (info) => {
          const value = info.getValue() as string
          const alarmType = AlarmTypeValues.find((alarm) => alarm.value === value)?.label ?? value
          return (
            <div className='font-poppins text-2xs leading-4  text-ellipsis font-normal  text-c-dark-blue-1 '>
              {alarmType}
            </div>
          )
        },
        meta: {},
        filterFn: 'equalsString',
      },
      {
        id: SafetyPageColumnIds.alarmLevel,
        header: '% Level',
        accessorKey: 'Level',
        cell: (info) => {
          const data = info.row.original
          let value: React.ReactNode = info.getValue() as string

          if (data.alarm === AlarmTypeValues[0].value || data.alarm === AlarmTypeValues[1].value) {
            value = (
              <p className='flex items-center'>
                <span className='text-lg'>{`> `}</span>
                100
              </p>
            )
          }

          return (
            <div className='font-poppins text-2xs leading-4  text-ellipsis font-normal  text-c-dark-blue-1 '>
              {value}
            </div>
          )
        },
        meta: {},
        filterFn: 'auto',
      },
      {
        id: SafetyPageColumnIds.alarmSetPoint,
        header: 'Peak value',
        accessorKey: 'Alarm1SetPoint',
        cell: (info) => {
          const value = info.getValue() as string
          return (
            <div className='font-poppins text-2xs leading-4  text-ellipsis font-normal  text-c-dark-blue-1 '>
              {value}
            </div>
          )
        },
        meta: {},
        filterFn: 'equalsString',
      },

      {
        id: SafetyPageColumnIds.deviceUser,
        header: 'Assigned To',
        accessorKey: 'AssignedTo',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <Tooltip
              id={`safety-assigned-to-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={value}
              toolTipClass='tooltip'
              className={` text-2xs leading-4 font-normal text-c-dark-blue-1  `}
            >
              {value}
            </Tooltip>
          )
        },
        meta: {
          shouldGlobalFilter: true,
        },
        filterFn: 'includesString',
      },
    ],
    [],
  )
  const renderFilterSummaryComponent = () => <> </>

  return (
    <>
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog}
        data={sortedData}
        reportColumns={SafetyReportColumns}
        widgetForReport={[]}
        reportTitle='Safety'
        filterSummary={filterSummary}
      />
      <TsTable
        ref={tableRef}
        columns={tableColumns}
        data={tableData ?? []}
        dataIsLoading={isLoading}
        showGlobalActionButton={false}
        showGlobalFilter
        onExport={() => {}}
        onPrint={() => {}}
        getRowCanExpand={() => true}
        // renderSubComponent={renderSubComponent}
        updateFilteredData={(data) => updateFilteredData(data)}
        renderFilterSummary={renderFilterSummaryComponent}
        resetFilter={() => onFilterFormReset()}
        onShowFilter={() => onShowFilter()}
        filterValues={filterValues}
        minDisplayRows={10}
        setShowExportDialog={(val) => setShowExportDialog(val)}
        // setFilterValues={setFilterValues}
        // dateRangeSelectorType={DateRangeSelectorTypes.DateRange}
        // dateRangeOptions={dateRangeOptions}
        // dateRangeFilterFn={DateRangeColumnFilterFn<iFaultData>}

        // Pagination
        manualPagination={false}
        defaultSortedColumKey={SafetyPageColumnIds.serialNumber}
        manualPageIndex={pageIndex}
        manualPageSize={pageSize}
        manualTotalRecordCount={totalRecords}
        onManualPageChange={(pageNum) => setPageIndex(pageNum)}
        onManualPageSizeChange={(pSize) => setPageSize(pSize)}
      />
    </>
  )
}

export default withErrorBoundary(SafetyPageTable, {
  FallbackComponent: ErrorPage,
})

import Tooltip from 'components/atom/Tooltip'
import { iDevice } from 'data/DeviceListHook'
import React, { memo, useEffect, useRef, useState, useCallback, useMemo } from 'react'
import clsx from 'clsx'
import { IFilterValues } from 'forms/FormModelInterface'
import { useSearchParams } from 'react-router-dom'
import {
  CalenderChildrenType,
  CalenderDateType,
  CalenderRefType,
} from 'components/modules/Calender/CalenderType'
import Calender from 'components/modules/Calender/Calender'
import { Transition } from '@headlessui/react'
import { DateRangeSelectorTypes } from 'utils/CommonEnums'
import {
  dayDateToDate,
  manageCalenderRangeDate,
  returnStaticDate,
} from 'components/modules/Calender/CalenderUtils'
import { ExpandIcon } from 'assets/icons'
import { FormattedPlural } from 'react-intl'
import { checkWeatherDeviceOverDue } from 'data/GenericDataUtils'
import CalibrationsCalenderHeader from './CalibrationCalenderHeader'
import {
  CalenderOverDueStatus,
  CalibrationPageColumnIds,
  CalibrationPageParamLabels,
} from '../../CalibrationsPageUtils'
import ZoomedCalibrationCalendarWidget, {
  ZoomedCalibrationDataWidget,
} from './ZoomedCalibrationCalendarWidget'

type CalibrationCalenderWidgetProps = {
  data: iDevice[]
  onFilter?: (filter: IFilterValues[]) => void
  onReset?: () => void
}

const DataDisplayWidget = memo(({ event, day }: CalenderChildrenType) => {
  const { displayValue: overdueStatus, isOverDue } = checkWeatherDeviceOverDue(day.date) as {
    displayValue: keyof typeof CalenderOverDueStatus
    isOverDue: boolean
  }
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 100)
    return () => clearTimeout(timer)
  }, [])

  if (!event) return null

  return (
    <Transition
      show={show}
      enter='transition-all scale-0 duration-150 delay-50 ease-in'
      enterFrom='opacity-0 scale-0'
      enterTo='opacity-100 scale-100'
      leave='transition-opacity duration-150 delay-50 ease-out'
      leaveFrom='opacity-100 scale-100'
      leaveTo='opacity-0 scale-0'
      className='absolute top-1/2 -translate-y-1/2 right-2 z-10'
    >
      <Tooltip
        tooltipText={
          <div className='z-50 w-full'>
            {isOverDue ? (
              <FormattedPlural
                value={event.length}
                one={`${event.length.toString().padStart(2, '0')} device is overdue`}
                other={`${event.length.toString().padStart(2, '0')} devices are overdue`}
              />
            ) : (
              <div className='text-4xs font-medium'>
                <FormattedPlural
                  value={event.length}
                  one={`${event.length.toString().padStart(2, '0')} device is due `}
                  other={`${event.length.toString().padStart(2, '0')} devices are due `}
                />
              </div>
            )}
          </div>
        }
        toolTipClass='tooltip text-4xs w-max px-2 m-auto -top-7 left-1/2 -translate-x-1/2  text-center '
      >
        <div
          className={clsx(
            CalenderOverDueStatus[overdueStatus],
            ' text-4xs  h-5 w-5 text-center py-[5px] rounded-full drop-shadow',
          )}
        >
          {event.length > 99 ? `99+` : `${event.length}`.padStart(2, '0')}
        </div>
      </Tooltip>
    </Transition>
  )
})

DataDisplayWidget.displayName = 'DataDisplayWidget'

function CalibrationCalenderWidget({ data, onFilter, onReset }: CalibrationCalenderWidgetProps) {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const ref = useRef<CalenderRefType>(null)
  const [dateRanges, setDateRanges] = useState<CalenderDateType[]>([])
  const [searchParams] = useSearchParams()
  const calDueDateToParam = searchParams.get(CalibrationPageParamLabels.calDueTo) ?? ''
  const calDueDateFromParam = searchParams.get(CalibrationPageParamLabels.calDueFrom) ?? ''
  const [zoom, setZoom] = useState(false)

  useEffect(() => {
    if (!searchParams.toString()) ref.current?.clearFilter?.()
  }, [searchParams])

  const onFilterCalender = useCallback(
    (selectedDateArray: CalenderDateType[]) => {
      const filterValues: IFilterValues[] = [
        {
          columnId: CalibrationPageColumnIds.calDue,
          value: {
            label: DateRangeSelectorTypes.Custom,
            value: {
              startDate: returnStaticDate(selectedDateArray[0] as Date, true),
              endDate: returnStaticDate(
                selectedDateArray[selectedDateArray.length - 1] as Date,
                true,
              ),
            },
          },
        },
      ]
      onFilter?.(filterValues)
    },
    [onFilter],
  )

  useEffect(() => {
    if (calDueDateFromParam && calDueDateToParam) {
      setDateRanges(
        manageCalenderRangeDate([
          returnStaticDate(dayDateToDate(calDueDateFromParam, true)),
          returnStaticDate(dayDateToDate(calDueDateToParam, true)),
        ]),
      )
    }
  }, [calDueDateFromParam, calDueDateToParam])

  const events = data.reduce((acc: { [key: string]: iDevice[] }, curr: iDevice) => {
    if (curr.calibrationDueDate) {
      const date = new Date(curr.calibrationDueDate.split('T')?.[0])
      const key = returnStaticDate(date)
      acc[key] = [...(acc[key] ?? []), curr]
    }
    return acc
  }, {})

  const onResetCalender = useCallback(() => {
    setDateRanges([])
    onReset?.()
  }, [onReset])

  const updateSelectedDate = useCallback((date: Date) => {
    setSelectedDate(date)
  }, [])

  const onRangeChange = useCallback((range: CalenderDateType[]) => {
    setDateRanges(range)
  }, [])

  const commonProps = useMemo(
    () => ({
      ref,
      events,
      defaultFilterApplied: Boolean(calDueDateFromParam && calDueDateToParam),
      shouldShowFilter: true,
      onFilter: onFilterCalender,
      onReset: onResetCalender,
      onChange: updateSelectedDate,
      onDateRangeChange: onRangeChange,
      date: selectedDate,
      selectedDateRange: dateRanges,
      title: <CalibrationsCalenderHeader data={data} date={selectedDate} />,
    }),
    [
      ref,
      events,
      onFilterCalender,
      onResetCalender,
      updateSelectedDate,
      onRangeChange,
      selectedDate,
      dateRanges,
      calDueDateFromParam,
      calDueDateToParam,
      data,
    ],
  )

  return (
    <div className='relative  group/header'>
      <div className='absolute top-0 right-0 p-2  z-10'>
        <button
          type='button'
          title='Zoom calender'
          className='invisible group-hover/header:visible'
          onClick={() => setZoom(true)}
        >
          <ExpandIcon className='w-5 h-5 stroke-c-blue ' />
        </button>
      </div>
      <Calender {...commonProps} render={(props) => <DataDisplayWidget {...props} />} />
      <ZoomedCalibrationCalendarWidget open={zoom} onClose={() => setZoom(false)}>
        <Calender {...commonProps} render={(props) => <ZoomedCalibrationDataWidget {...props} />} />
      </ZoomedCalibrationCalendarWidget>
    </div>
  )
}

CalibrationCalenderWidget.defaultProps = {
  onFilter: undefined,
  onReset: undefined,
}

export default memo(CalibrationCalenderWidget)

import React from 'react'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { intlFormat } from 'date-fns'

const AssignmentHistoryReportColumns: ExportColumnType[] = [
  {
    key: 'fullName',
    label: 'Name',
    format: (val: string) => val,
    cellWidth: 120,
  },
  {
    key: 'uniqueId',
    label: 'Unique ID',
    format: (val: string) => val,
    cellWidth: 100,
  },
  {
    key: 'assignedOn',
    label: 'Assigned on',
    format: (val: string) =>
      val
        ? intlFormat(new Date(val), {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })
            .split(',')
            .join(' ')
        : 'Not available',
    cellWidth: 100,
  },
  {
    key: 'unAssignedOn',
    label: 'Returned on',
    format: (val: string) =>
      val
        ? intlFormat(new Date(val), {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })
            .split(',')
            .join(' ')
        : 'Not available',
    cellWidth: 100,
  },
]

export { AssignmentHistoryReportColumns }
